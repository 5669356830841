import { PaymentTransactionRow } from './Interfaces/PaymentTransactionRow';
import PopupDialog from '../Components/Dialog/PopupDialog';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box, Grid, LinearProgress, LinearProgressProps, Paper, Typography } from '@mui/material';
import instance from '../axios';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import moment from 'moment-timezone';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  selectedJobId: number;
  onClose: () => void;
}

export default function PARegistryUploadJob(props: Props) {
  const [logs, setLogs] = useState<{ date: number; message: string }[]>([]);
  const [progress, setProgress] = useState<number>(0);

  const getJobInfo = (backendId: number, jobId: number) => {
    instance
      .get(`/pa/1/registry/upload-job/${jobId}`)
      .then((value) => {
        setLogs(value.data.logs);
        setProgress(value.data.job.progress);
        console.log(value.data);
      })
      .catch((reason) => {
        // setDataLoading(false)
      });
  };

  useEffect(() => {
    getJobInfo(1, props.selectedJobId);
    // setInterval(()=>{
    //     setText((v)=>`${new Date()} - hjklkjihugyftd\n${v}`)
    // }, 1000)
  }, [props.selectedJobId]);
  return (
    <React.Fragment>
      {
        <PopupDialog title="" onClose={props.onClose.bind(null)} maxWidth={'sm'}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((v, i) => (
                  <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {moment(new Date(v.date)).tz('Europe/Kiev').format('DD‑MM‑YY HH:mm z')}
                    </TableCell>
                    <TableCell>{v.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/*<LinearProgressWithLabel value={progress} />*/}
        </PopupDialog>
      }
    </React.Fragment>
  );
}
