import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper
} from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import AppMenu from '../AppBar/AppMenu';
import CssBaseline from '@mui/material/CssBaseline';
import instance from '../axios';
import CreateIcon from '@mui/icons-material/Create';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import TaskEditDialog from './TaskEditDialog';

export interface UserListItem {
  id: number;
  login: string;
  isActive: boolean;
}

export default function TaskList() {
  const [taskList, setTaskList] = useState<{ id: number; enabled: boolean; name: string }[]>([]);

  const [selectedTaskId, setSelectedTaskId] = useState<number>(0);

  const loadTaskList = () => {
    instance
      .get('/task/list')
      .then((value) => {
        setTaskList(value.data);
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    loadTaskList();
  }, []);

  return (
    <React.Fragment>
      <AppMenu header={'System user'} />
      <CssBaseline />
      {/*<div style={{display: "flex"}}>*/}
      {/*    <Button sx={{mx: 2, mt: 2}} variant="outlined" fullWidth onClick={()=>{setNeedCreateUser(true)}}>Create new user</Button>*/}
      {/*</div>*/}
      <Paper sx={{ m: 2 }}>
        <List>
          {taskList.map((value, index) => (
            <ListItem disabled={!value.enabled}>
              <ListItemAvatar aria-disabled={!value.enabled}>
                <Avatar>
                  {value.enabled ? <HourglassEmptyIcon /> : <HourglassDisabledIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText color="red" primary={value.name} />
              <ListItemSecondaryAction>
                <React.Fragment>
                  <IconButton
                    edge="end"
                    aria-label="restore"
                    onClick={() => {
                      setSelectedTaskId(value.id);
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                </React.Fragment>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
      {selectedTaskId > 0 && (
        <TaskEditDialog
          taskId={selectedTaskId}
          onClose={() => {
            setSelectedTaskId(0);
          }}
        />
      )}
      {/*{*/}
      {/*    isNeedCreateUser &&*/}
      {/*    <UserCreateDialog reloadParent={()=>{loadUserList()}} onClose={()=>{setNeedCreateUser(false)}}/>*/}
      {/*}*/}
      {/*{*/}
      {/*    isNeedRestoreUser &&*/}
      {/*    <UserRestoreDialog userId={restoringUserId} reloadParent={()=>{loadUserList()}} onClose={()=>{setRestoringUserId(0); setNeedRestoreUser(false);}}/>*/}
      {/*}*/}
    </React.Fragment>
  );
}
