import { useEffect, useState } from 'react';
import React from 'react';
import instance from '../../axios';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface Props {
  backend: number;
  disabled: boolean;
}

export default function OQSettingsMaintenance(props: Props) {
  const [isMaintenanceEnable, setMaintenanceEnable] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const loadCurrentMode = (backend: number) => {
    instance
      .get(`/oq/s2s/${backend}/maintenance`)
      .then((value) => {
        setMaintenanceEnable(value.data);
      })
      .catch((reason) => {});
  };

  const setCurrentMode = (backend: number, isMaintenanceEnable: boolean) => {
    instance
      .post(`/oq/s2s/${backend}/maintenance`, { isMaintenanceEnable })
      .then((value) => {
        loadCurrentMode(backend);
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    if (props.backend > 0) {
      loadCurrentMode(props.backend);
    }
  }, [props.backend]);

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setCurrentMode(props.backend, e.target.checked);
                }}
                checked={isMaintenanceEnable}
              />
            }
            label="Maintenance mode enabled "
            disabled={isDisabled}
          />
        </FormGroup>
      </div>
    </React.Fragment>
  );
}
