import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import encryptWithPublicKey from '../crypto';
import setCredential from '../Auth/setCredential';
import { Alert } from '@mui/material';
import { useState } from 'react';
import instance from '../axios';

export default function SignIn() {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [signInError, setSignInError] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // const encryptedData = await encryptWithPublicKey({
    //   login: data.get('login'),
    //   password: data.get('password')
    // });
    const encryptedData = {
      login: data.get('login'),
      password: data.get('password')
    };

    instance
      .post<{ access: string; refresh: string }>('/user/signin', encryptedData)
      .then((res) => {
        setCredential(res.data.access, res.data.refresh);
        window.location.href = '/';
      })
      .catch((reason) => {
        setSignInError(reason.response.data.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="login"
            label="Login"
            name="login"
            autoComplete="login"
            autoFocus
            value={login}
            onChange={(e) => {
              setLogin(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {signInError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {signInError}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            disabled={password.length == 0 || login.length == 0}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
