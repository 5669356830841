import AppMenu from '../AppBar/AppMenu';
import { Alert, Box, CssBaseline } from '@mui/material';
import PABackendSelect from './PABackendSelect';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PATerminalSelect from './PATerminalSelect';
import instance from '../axios';

export default function PASanctionsList() {
  const [unlockError, setUnlockError] = useState<string>('');
  const [unlockInProcess, setUnlockInProcess] = useState<boolean>(false);

  const [backendSelected, setBackendSelected] = useState<number>(0);
  const [terminalSelected, setTerminalSelected] = useState<string>('');

  const [customerFLN, setCustomerFLN] = useState<string>('');

  const unlockSanctionsList = (backend: number, terminal: string, customer: string) => {
    setUnlockInProcess(true);
    instance
      .post(`/pa/${backend}/sanctions-list/unblock`, { terminal, customer })
      .then((value) => {
        if (value.data == false) {
          setUnlockError('error on processing');
          setTimeout(() => setUnlockError(''), 5000);
        } else {
          setCustomerFLN('');
        }
        setUnlockInProcess(false);
      })
      .catch((reason) => {
        setUnlockError(reason.message);
        setTimeout(() => setUnlockError(''), 5000);
        setUnlockInProcess(false);
      });
  };

  return (
    <React.Fragment>
      <AppMenu header={'Sanctions list'} />
      <CssBaseline />
      <Box sx={{ mx: 1, mt: 1 }}>
        <PABackendSelect
          backend={backendSelected}
          setBackendId={(v) => {
            setBackendSelected(v);
          }}
          disabled={unlockInProcess}
        />
      </Box>
      <Box sx={{ mx: 1, mt: 1 }}>
        <PATerminalSelect
          terminal={terminalSelected}
          disabled={!backendSelected || unlockInProcess}
          backend={backendSelected}
          setTerminalId={(v) => {
            setTerminalSelected(v);
          }}
        />
      </Box>
      <Box sx={{ mx: 1, mt: 1 }}>
        <TextField
          disabled={!backendSelected || !terminalSelected || unlockInProcess}
          label="Customer FLN"
          variant="outlined"
          fullWidth
          value={customerFLN}
          onChange={(e) => {
            setCustomerFLN(e.target.value);
          }}
        />
      </Box>
      <Box sx={{ mx: 1, mt: 1 }}>
        <Button
          variant="outlined"
          fullWidth
          color={'success'}
          size="large"
          disabled={!backendSelected || !customerFLN || !terminalSelected || unlockInProcess}
          onClick={() => {
            unlockSanctionsList(backendSelected, terminalSelected, customerFLN);
          }}
        >
          Unblock
        </Button>
      </Box>
      <Box sx={{ mx: 1, mt: 1 }}>
        {unlockError && <Alert severity="error">{unlockError}</Alert>}
      </Box>
    </React.Fragment>
  );
}
