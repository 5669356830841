import { useEffect, useState } from 'react';
import React from 'react';
import instance from '../../axios';
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';

interface GroupedRules {
  dept: string;
  deptRules: {
    id: number;
    provider: number;
  }[];
}

interface Props {
  backend: number;
  disabled: boolean;
}

export default function OQSettingsPaymentProviders(props: Props) {
  const [rulesList, setRulesList] = useState<GroupedRules[]>([]);
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const loadProviderDeptRules = (backend: number) => {
    instance
      .get(`/oq/s2s/${backend}/payment-providers/provider-dept-rules`)
      .then((value) => {
        // console.log(value.data)
        const groupedRules: GroupedRules[] = [];
        if (value.data instanceof Array) {
          for (const providerRule of value.data) {
            const existedRule = groupedRules.find((v) => v.dept == providerRule.dept);
            if (existedRule)
              existedRule.deptRules.push({ id: providerRule.id, provider: providerRule.provider });
            else
              groupedRules.push({
                dept: providerRule.dept,
                deptRules: [{ id: providerRule.id, provider: providerRule.provider }]
              });
          }
        }
        setRulesList(groupedRules);
      })
      .catch((reason) => {});
  };

  // const setCurrentMode = (backend: number, isMaintenanceEnable: boolean) => {
  //     instance.post(`/oq/s2s/${backend}/maintenance`, {isMaintenanceEnable}).then(value => {
  //         loadCurrentMode(backend)
  //     }).catch(reason => {
  //
  //     })
  // }

  useEffect(() => {
    if (props.backend > 0) {
      loadProviderDeptRules(props.backend);
    }
  }, [props.backend]);

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  return (
    <React.Fragment>
      {/*<div style={{display: "flex", flexDirection: "column", height: "100vh"}}>*/}
      <Paper sx={{ m: 2 }}>
        <List>
          {rulesList.map((value, index) => (
            <ListItem
              secondaryAction={
                <React.Fragment>
                  <IconButton aria-label="delete" onClick={() => {}}>
                    <EditIcon />
                  </IconButton>
                  {/*<IconButton edge="end" aria-label="delete" color={"error"} onClick={()=>{setDeletedUserId(value.id)}}>*/}
                  {/*    <DeleteIcon />*/}
                  {/*</IconButton>*/}
                </React.Fragment>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={value.dept} />
            </ListItem>
          ))}
        </List>
      </Paper>

      {/*</div>*/}
    </React.Fragment>
  );
}
