import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  CircularProgress,
  Grid,
  styled,
  Tab,
  Tabs
} from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppMenu from '../../AppBar/AppMenu';
import instance from '../../axios';
import OQBackendSelect from '../OQBackendSelect';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import OQSettingsMaintenance from './OQSettingsMaintenance';
import OQSettingsPaymentProviders from './OQSettingsPaymentProviders';

export interface OnlineQrBackendItem {
  id: number;
  unique: string;
  date: string;
  cust: string;
  custLat: string;
  p: string;
  dob: string;
  doc: string;
  rnokpp: string;
}

const columns: GridColDef[] = [
  { field: 'unique', headerName: 'UUID', width: 300 },
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'date', headerName: 'Date', width: 100 },
  { field: 'custCyr', headerName: 'Customer', width: 250 },
  { field: 'custLat', headerName: 'Customer', width: 250 },
  { field: 'phone', headerName: 'Phone', width: 150 },
  { field: 'dob', headerName: 'Birthday', width: 100 },
  { field: 'doc', headerName: 'Document', width: 120 },
  { field: 'rnokpp', headerName: 'Vat number', width: 120 }
];

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.Mui-expanded': {
    marginTop: 0
  }
}));

export default function OQSettings() {
  const [backendSelected, setBackendSelected] = useState<number>(0);

  useEffect(() => {}, [backendSelected]);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppMenu header={'Online qr'} />
        <CssBaseline />
        <Box sx={{ mx: 1, mt: 1 }}>
          <OQBackendSelect
            setBackendId={(v) => {
              setBackendSelected(v);
            }}
          />
        </Box>
        <Box sx={{ mx: 0, mt: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Maintenance" />
              <Tab label="Payment providers" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <OQSettingsMaintenance backend={backendSelected} disabled={backendSelected == 0} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <OQSettingsPaymentProviders backend={backendSelected} disabled={backendSelected == 0} />
          </TabPanel>
        </Box>
      </div>
    </React.Fragment>
  );
}
