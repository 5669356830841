import React, { useEffect, useState } from 'react';
import PopupDialog from '../Components/Dialog/PopupDialog';
import { Box, darken, IconButton, lighten, styled } from '@mui/material';
import PABackendSelect from './PABackendSelect';
import { PaymentTransactionRow } from './Interfaces/PaymentTransactionRow';
import instance from '../axios';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment/moment';
import PAPaymentActionDialog from './PAPaymentActionDialog';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        )
      }
    }
  },
  '& .super-app-theme--MainDocument': {
    backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        )
      }
    }
  },
  '& .super-app-theme--PendingDeletion': {
    backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.warning.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        )
      }
    }
  },
  '& .super-app-theme--Deleted': {
    backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        )
      }
    }
  }
}));

const columns: GridColDef[] = [
  // { field: 'docid', headerName: 'Document', width: 100 },
  { field: 'transname', headerName: 'Transaction', width: 140 },
  {
    field: 'datetime',
    headerName: 'Date',
    width: 150,
    valueGetter: ({ value }) => moment(value).format('DD-MM-YYYY HH:mm')
  },
  { field: 'amount', headerName: 'Amount', width: 80 },
  { field: 'comis', headerName: 'Fee', width: 80 }
];

interface Props {
  uuid: string;
  backend?: number;
  onClose: () => void;
}

export default function PATransactionInfo(props: Props) {
  const [paymentDocuments, setPaymentDocuments] = useState<PaymentTransactionRow[]>([]);

  const [backendSelected, setBackendSelected] = useState<number>(0);

  const [selectedDocument, setSelectedDocument] = useState<PaymentTransactionRow | undefined>(
    undefined
  );

  const loadTransactionsByUUID = (uuid: string, backend: number) => {
    instance
      .post(`/pa/${backend}/operations/extended/by-uuid`, { uuid })
      .then((value) => {
        console.log(value.data);
        setPaymentDocuments(value.data);
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    if (props.backend) setBackendSelected(props.backend);
  }, [props.backend]);

  useEffect(() => {
    if (props.uuid && backendSelected) {
      loadTransactionsByUUID(props.uuid, backendSelected);
    }
  }, [props.uuid, backendSelected]);

  return (
    <PopupDialog
      title={props.uuid}
      onClose={() => {
        props.onClose();
      }}
      maxWidth={'sm'}
      actions={
        <React.Fragment>
          {/*<IconButton href={`https://qr.pasport.org.ua/v2/${props.uuid}`} target='_blank' disabled={!backendSelected}>*/}
          {/*    <ShareIcon />*/}
          {/*</IconButton>*/}
          {/*<IconButton onClick={()=>{loadAttemptsHistory(props.uuid, backendSelected)}} disabled={!backendSelected}>*/}
          {/*    <AutorenewIcon/>*/}
          {/*</IconButton>*/}
        </React.Fragment>
      }
    >
      <React.Fragment>
        {!props.backend && (
          <Box sx={{ mt: 1 }}>
            <PABackendSelect
              backend={backendSelected}
              setBackendId={(v) => {
                setBackendSelected(v);
              }}
            />
          </Box>
        )}
        <Box sx={{ mt: 1 }}>
          <StyledDataGrid
            onRowClick={(params, event) => {
              setSelectedDocument(params.row);
            }}
            // rowHeight={30}
            disableColumnFilter
            loading={false}
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            autoPageSize
            autoHeight
            pageSize={20}
            // rowCount={10}
            disableSelectionOnClick
            rows={paymentDocuments}
            columns={columns}
            getRowId={(row: any) => `${row.docid}`}
            getRowClassName={(params) => {
              switch (params.row.deleted) {
                case '1':
                  return `super-app-theme--Deleted`;
                case '2':
                  return `super-app-theme--PendingDeletion`;
                default:
                  if (params.row.parentid == '0') return `super-app-theme--MainDocument`;
                  return ``;
              }
            }}
          />
        </Box>

        {selectedDocument && (
          <PAPaymentActionDialog
            terminal={selectedDocument.terminalid}
            data={selectedDocument}
            backend={backendSelected}
            onClose={() => setSelectedDocument(undefined)}
            needReload={() => {
              loadTransactionsByUUID(props.uuid, backendSelected);
            }}
          />
        )}
      </React.Fragment>
    </PopupDialog>
  );
}
