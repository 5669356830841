import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Outlet, Route, Router, Routes } from 'react-router-dom';
import GuardedRoute from './Auth/GuardedRoute';
import Overview from './Overview/Overview';
import SignIn from './SignIn/SignIn';
import UserList from './User/UserList';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider, useTheme } from '@mui/material';
import { UserPermissionList } from './enums/UserPermissionList';
import { UserPermissionItem } from './interfaces/UserPermissionItem';
import { AxiosInterceptor } from './axios';
import OQBackendList from './OQ/OQBackendList';
import OQCartList from './OQ/Cart/OQCartList';
import OQSettings from './OQ/Settings/OQSettings';
import instance from './axios';
import OQProcessingTest from './OQ/Processing/OQProcessingTest';
import PAPaymentsList from './PA/PAPaymentsList';
import PASanctionsList from './PA/PASanctionsList';
import PARegistryList from './PA/PARegistryList';
import TaskList from './Task/TaskList';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
export const UIPermissionContext = React.createContext({
  check: (permissions: UserPermissionList[]): boolean => false
});

function App() {
  const [userPermissionsList, setUserPermissionsList] = useState<UserPermissionItem[]>([]);

  const loadUserPermissions = () => {
    instance
      .get(`/user/permissions`)
      .then((value) => {
        console.log(value.data.permissions);
        setUserPermissionsList([
          ...value.data.permissions,
          ...value.data.onlineQrPermissions,
          ...value.data.paymentAdminPermissions
        ]);
      })
      .catch((reason) => {});
  };

  const uiPermission = React.useMemo(
    () => ({
      check: (permissions: UserPermissionList[]): boolean => {
        if (userPermissionsList === undefined || userPermissionsList.length === 0) return false;
        for (const permissionItem of permissions) {
          if (userPermissionsList.find((v) => v.code === permissionItem) === undefined)
            return false;
        }
        return true;
      }
    }),
    [userPermissionsList]
  );

  useEffect(() => {
    loadUserPermissions();
  }, []);

  const [mode, setMode] = React.useState<'light' | 'dark'>(
    (localStorage.getItem('theme') as any) ||
      (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          localStorage.setItem('theme', prevMode === 'light' ? 'dark' : 'light');
          return prevMode === 'light' ? 'dark' : 'light';
        });
      }
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode
          // primary: {
          //     main: indigo[600],
          //     light: grey[700]
          // },
          // secondary: {
          //     main: green[500],
          //     light: grey[700]
          // },
          //
          // background: {
          //     default: grey[800],
          //     paper: grey[800],
          // },
          //
          // divider: amber[200],
          // text: {
          //     primary: grey[900],
          //     secondary: grey[800],
          // }
        }
      }),
    [mode]
  );

  return (
    <AxiosInterceptor>
      <UIPermissionContext.Provider value={uiPermission}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                {/*<Route path='/' element={<GuardedRoute/>}>*/}
                <Route path="/" element={<Overview />} />
                <Route path="/user" element={<UserList />} />
                <Route path="/oq/backend" element={<OQBackendList />} />
                <Route path="/oq/cart" element={<OQCartList />} />
                <Route path="/oq/settings" element={<OQSettings />} />
                <Route path="/oq/processing/test" element={<OQProcessingTest />} />
                <Route path="/pa/transactions" element={<PAPaymentsList />} />
                <Route path="/pa/sanctions-list" element={<PASanctionsList />} />
                <Route path="/pa/registry" element={<PARegistryList />} />
                <Route path="/task/list" element={<TaskList />} />
                <Route path="*" element={<Navigate to="/" />} />
                {/*</Route>*/}

                <Route path="/signin" element={<SignIn />} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </UIPermissionContext.Provider>
    </AxiosInterceptor>
  );
}

export default App;
