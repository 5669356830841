import React, { useEffect, useState } from 'react';
import PopupDialog from '../../Components/Dialog/PopupDialog';
import instance from '../../axios';
import { Avatar, Badge, Button, Card, CardActions, CardHeader, IconButton } from '@mui/material';
import { blue, green, orange, red } from '@mui/material/colors';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ShareIcon from '@mui/icons-material/Share';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PaymentAcquiring from './PaymentAcquiring';
import EmailResend from './EmailResend';
import OQBackendSelect from '../OQBackendSelect';
import Box from '@mui/material/Box';
import PATransactionInfo from '../../PA/PATransactionInfo';
import NotificationsIcon from '@mui/icons-material/Notifications';
import QrSrc from "./QrSrc";

const getProviderNameByTypeId = (type: number) => {
  switch (type) {
    case 0:
      return 'Oschadbank';
    case 1:
      return 'Monobank';
    case 10:
      return 'Fenige';
    default:
      return '';
  }
};

const getAvatar = (status: number) => {
  switch (status) {
    case 0:
      return (
        <Avatar sx={{ bgcolor: green[500] }} variant="square">
          S
        </Avatar>
      );
    case 1:
    case 200:
      return (
        <Avatar sx={{ bgcolor: blue[500] }} variant="square">
          W
        </Avatar>
      );
    case 2:
      return (
        <Avatar sx={{ bgcolor: orange[500] }} variant="square">
          P
        </Avatar>
      );
    case 100:
      return (
        <Avatar sx={{ bgcolor: red[500] }} variant="square">
          L
        </Avatar>
      );
  }
};

const getStatusDescription = (status: number) => {
  switch (status) {
    case 0:
      return 'Paid online';
    case 1:
    case 200:
      return 'Ready';
    case 2:
      return 'Wait confirmation from acquire';
    case 100:
      return 'Refunded';
  }
};

interface Props {
  uuid: string;
  backend?: number;
  onClose: () => void;
}

interface CartAttemptProvider {
  paymentStatus: number;
  paymentTerminal: {
    id: number;
    name: string;
    provider: {
      id: number;
      type: number;
    };
  };
  paymentProvider: {
    id: number;
    type: number;
    processingUrl: string;
    currency: string;
    currencyCode: number;
    currencyLocal: string;
    name: string;
  };
  isMultistage: boolean;
}

export default function PaymentInfo(props: Props) {
  const [attempt, setAttempt] = useState<CartAttemptProvider[]>([]);
  const [url, setUrl] = useState<string>();
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<number>(0);

  const [selectedPaymentAdminUnique, setSelectedPaymentAdminUnique] = useState<string>('');

  const [backendSelected, setBackendSelected] = useState<number>(0);

  const [isNeedEmailResend, setNeedEmailResend] = useState<boolean>(false);
  const [isNeedShowPaymentHistory, setNeedShowPaymentHistory] = useState<boolean>(false);
  const [isNeedShowQrSrc, setNeedShowQrSrc] = useState<boolean>(false);

  const loadAttemptsHistory = (uuid: string, backend: number) => {
    instance
      .post(`/oq/s2s/${backend}/qr/cart/attempts`, { uuid })
      .then((value) => {
        if (value.data.attempts) {
          setAttempt(value.data.attempts);
          setUrl(value.data.url);
        } else {
          setAttempt(value.data);
        }
      })
      .catch((reason) => {});
  };

  const manualTrigger = (uuid: string, backend: number, paymentProviderId: number) => {
    instance
      .post(`/oq/s2s/${backend}/qr/cart/manual-status`, { uuid, paymentProviderId })
      .then((value) => {})
      .catch((reason) => {});
  };

  useEffect(() => {
    if (props.backend) setBackendSelected(props.backend);
  }, [props.backend]);

  useEffect(() => {
    if (props.uuid && backendSelected) {
      loadAttemptsHistory(props.uuid, backendSelected);
    }
  }, [props.uuid, backendSelected]);

  return (
    <PopupDialog
      title={props.uuid}
      onClose={() => {
        props.onClose();
      }}
      maxWidth={'sm'}
      actions={
        <React.Fragment>
          <Button
            onClick={() => {
              setSelectedPaymentAdminUnique(props.uuid);
            }}
            disabled={!backendSelected}
          >
            Search Transactions
          </Button>
          <IconButton
              onClick={() => {
                setNeedShowQrSrc(true);
              }}
          >
            <QrCodeIcon />
          </IconButton>
          <IconButton
            href={url ? url : `https://qr.pasport.org.ua/v2/${props.uuid}`}
            target="_blank"
            disabled={!backendSelected}
          >
            <ShareIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              loadAttemptsHistory(props.uuid, backendSelected);
            }}
            disabled={!backendSelected}
          >
            <AutorenewIcon />
          </IconButton>
        </React.Fragment>
      }
    >
      <React.Fragment>
        {!props.backend && (
          <Box sx={{ mt: 1 }}>
            <OQBackendSelect
              setBackendId={(v) => {
                setBackendSelected(v);
              }}
            />
          </Box>
        )}
        {attempt.map((value, index) => (
          <Card sx={{ mt: 2, width: '100%' }} key={`it-${index}`}>
            <CardHeader
              avatar={getAvatar(value.paymentStatus)}
              title={`${getProviderNameByTypeId(
                value.paymentProvider
                  ? value.paymentProvider.type
                  : value.paymentTerminal
                  ? value.paymentTerminal.provider.type
                  : -1
              )} ( ${
                value.paymentProvider
                  ? value.paymentProvider.name
                  : value.paymentTerminal
                  ? value.paymentTerminal.name
                  : ''
              } )`}
              subheader={`${getStatusDescription(value.paymentStatus)}`}
            />
            <CardActions disableSpacing>
              <IconButton
                href={`https://pa.airas.com.ua/api/oq/s2s/${backendSelected}/qr/cart/operator-pdf/${
                  props.uuid
                }/${
                  value.paymentProvider
                    ? value.paymentProvider.id
                    : value.paymentTerminal
                    ? value.paymentTerminal.id
                    : ''
                }`}
                target="_blank"
                disabled={value.paymentStatus !== 0}
              >
                <Badge badgeContent="O" color="primary">
                  <PictureAsPdfIcon />
                </Badge>
              </IconButton>

              <IconButton
                href={`https://pa.airas.com.ua/api/oq/s2s/${backendSelected}/qr/cart/client-pdf/${
                  props.uuid
                }/${
                  value.paymentProvider
                    ? value.paymentProvider.id
                    : value.paymentTerminal
                    ? value.paymentTerminal.id
                    : ''
                }`}
                target="_blank"
                disabled={value.paymentStatus !== 0}
              >
                <Badge badgeContent="C" color="primary">
                  <PictureAsPdfIcon />
                </Badge>
              </IconButton>

              <IconButton
                onClick={() => {
                  setSelectedPaymentProvider(
                    value.paymentProvider
                      ? value.paymentProvider.id
                      : value.paymentTerminal
                      ? value.paymentTerminal.id
                      : -1
                  );
                  setNeedEmailResend(true);
                }}
                disabled={value.paymentStatus !== 0}
              >
                <AlternateEmailIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  setSelectedPaymentProvider(
                    value.paymentProvider
                      ? value.paymentProvider.id
                      : value.paymentTerminal
                      ? value.paymentTerminal.id
                      : -1
                  );
                  setNeedShowPaymentHistory(true);
                }}
                disabled={
                  !(
                    value.paymentStatus === 0 ||
                    value.paymentStatus === 100 ||
                    value.paymentStatus === 200 ||
                    value.paymentStatus === 1
                  )
                }
              >
                <CreditCardIcon />
              </IconButton>

              {/*<IconButton onClick={()=>{manualTrigger(props.uuid, backendSelected, value.paymentProvider ? value.paymentProvider.id : value.paymentTerminal ? value.paymentTerminal.id : -1)}} disabled={value.paymentStatus == 0 || value.paymentStatus == 100}>*/}
              <IconButton
                onClick={() => {
                  manualTrigger(
                    props.uuid,
                    backendSelected,
                    value.paymentProvider
                      ? value.paymentProvider.id
                      : value.paymentTerminal
                      ? value.paymentTerminal.id
                      : -1
                  );
                }}
              >
                <NotificationsIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
        {selectedPaymentProvider > 0 && isNeedShowPaymentHistory && (
          <PaymentAcquiring
            provider={selectedPaymentProvider}
            backend={backendSelected}
            uuid={props.uuid}
            onClose={() => {
              setSelectedPaymentProvider(0);
              setNeedShowPaymentHistory(false);
            }}
          />
        )}
        {selectedPaymentProvider > 0 && isNeedEmailResend && (
          <EmailResend
            provider={selectedPaymentProvider}
            backend={backendSelected}
            uuid={props.uuid}
            onClose={() => {
              setSelectedPaymentProvider(0);
              setNeedEmailResend(false);
            }}
          />
        )}
        {selectedPaymentAdminUnique && (
          <PATransactionInfo
            uuid={props.uuid}
            onClose={() => {
              setSelectedPaymentAdminUnique('');
            }}
          />
        )}
        {
          isNeedShowQrSrc &&
            <QrSrc uuid={props.uuid} backend={props.backend} onClose={()=>{
              setNeedShowQrSrc(false)
            }}/>
        }
      </React.Fragment>
    </PopupDialog>
  );
}
