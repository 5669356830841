import React, { useEffect, useState } from 'react';
import PopupDialog from '../../Components/Dialog/PopupDialog';
import instance from '../../axios';
import { Avatar, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import CircleIcon from '@mui/icons-material/Circle';
import PaymentReverseDialog from './PaymentReverseDialog';
import moment from 'moment-timezone';
import {UIPermissionContext} from "../../App";
import {UserPermissionList} from "../../enums/UserPermissionList";

const getStatusIcon = (status: number) => {
  switch (status) {
    case 0:
      return <CheckCircleIcon color={'success'} />;
    case 100:
      return <NextPlanIcon color={'warning'} />;
    default:
      return <CircleIcon color={'disabled'} />;
  }
};

const paymentRow = (rowData: any, currency: any, reverse: (id: number) => void, reverseOnSuccess: (id: number, enable: boolean, terminal: number) => void, allowChangeReverseOnSuccess: boolean) => {
  const isOschad = rowData.hasOwnProperty('lastServerStatus');

  const amount = isOschad ? rowData.paymentServerAmount : rowData.paymentAmount;
  const status = isOschad ? rowData.lastServerStatus : rowData.paymentStatus;
  const date = isOschad ? rowData.lastServerDatetime : rowData.createDatetime;

  return (
    <TableRow key={rowData.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {moment(new Date(date))
          .tz(currency.num == 980 ? 'Europe/Kiev' : 'Europe/Warsaw')
          .format('DD‑MM‑YYYY HH:mm z')}
        {/*{date && new Date(date).toISOString()}*/}
      </TableCell>
      <TableCell align="right">{rowData.paymentOrderId}</TableCell>
      <TableCell align="right">
        {rowData.paymentBackrefRRN ||
          rowData.paymentServerRRN ||
          rowData.paymentRRN ||
          rowData.paymentExternalOrderUniqueId}
      </TableCell>
      <TableCell align="right">{rowData.payerEmail}</TableCell>
      <TableCell align="right">{amount}</TableCell>
      <TableCell align="center">{getStatusIcon(status)}</TableCell>
      <TableCell align="center">
          <div style={{display: "flex"}}>
              <Button
                  variant="outlined"
                  size={'small'}
                  onClick={() => {
                      reverse(rowData.id);
                  }}
                  disabled={status !== 0}
                  fullWidth
                  color={'error'}
              >
                  Reverse
              </Button>
              {
                  allowChangeReverseOnSuccess &&
                  <Button
                      variant="outlined"
                      size={'small'}
                      onClick={() => {
                          reverseOnSuccess(rowData.id, !rowData.reverseOnSuccess, rowData.terminal.id);
                      }}
                      disabled={status == 0 && status == 100}
                      fullWidth
                      color={'warning'}
                  >
                      {rowData.reverseOnSuccess? 'Unlock': 'Lock'}
                  </Button>
              }

          </div>
      </TableCell>
    </TableRow>
  );
};

interface Props {
  uuid: string;
  backend: number;
  provider: number;
  onClose: () => void;
}

export default function PaymentAcquiring(props: Props) {
    const uiPermission = React.useContext(UIPermissionContext);
  const [payment, setPayment] = useState<any>();
  const [statuses, setAttempt] = useState<any[]>([]);
  const [selectedReverseId, setSelectedReverseId] = useState<number>(0);

  const loadAttemptsHistory = (uuid: string, backend: number, paymentProviderId: number) => {
    instance
      .post(`/oq/s2s/${backend}/qr/cart/attempts/extended`, { uuid, paymentProviderId })
      .then((value) => {
        setPayment(value.data);
        setAttempt(value.data.payment.statuses);
      })
      .catch((reason) => {});
  };

    const setReverseOnSuccess = (backend: number, attemptId: number, enable: boolean, paymentTerminalId: number) => {
        instance
            .post(`/oq/s2s/${backend}/qr/cart/reverse-schedule`, { paymentTerminalId, attemptId, enable })
            .then((value) => {
                loadAttemptsHistory(props.uuid, props.backend, props.provider);
            })
            .catch((reason) => {
                loadAttemptsHistory(props.uuid, props.backend, props.provider);
            });
    };

  useEffect(() => {
    if (props.uuid && props.backend) {
      loadAttemptsHistory(props.uuid, props.backend, props.provider);
    }
  }, [props.uuid, props.backend]);

  return (
    <React.Fragment>
      <PopupDialog
        title={props.uuid}
        maxWidth={'lg'}
        onClose={() => {
          props.onClose();
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>RRN</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">#</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statuses.map((value, index) =>
                paymentRow(value, payment.currency,
                    (id) => {
                  setSelectedReverseId(id);
                },
                    (id, enable, terminal) => {
                        setReverseOnSuccess(props.backend, id, enable, terminal)
                }, uiPermission.check([UserPermissionList.AllowQrCartItemMakeReverseOnSuccess]))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PopupDialog>
      {selectedReverseId > 0 && (
        <PaymentReverseDialog
          payment={selectedReverseId}
          provider={props.provider}
          backend={props.backend}
          uuid={props.uuid}
          onClose={() => {
            setSelectedReverseId(0);
          }}
        />
      )}
    </React.Fragment>
  );
}
