import Typography from '@mui/material/Typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PopupDialog from '../Components/Dialog/PopupDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import instance from '../axios';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

function ScheduleSettings() {
  const weekday: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return (
    <>
      <FormControl required component="fieldset" variant="standard">
        <FormLabel component="legend">Regular day off</FormLabel>
        <FormGroup row>
          {weekday.map((v, i) => (
            <FormControlLabel
              control={<Checkbox checked={false} onChange={() => {}} name="gilad" />}
              label={v}
            />
          ))}
        </FormGroup>
        <FormHelperText>This value </FormHelperText>
      </FormControl>
    </>
  );
}

export interface Props {
  taskId: number;
  onClose: () => void;
}

export default function TaskEditDialog(props: Props) {
  const [isNeedResetPassword, setNeedResetPassword] = useState<boolean>(false);

  const [selectedTaskId, setSelectedTaskId] = useState<number>(0);

  const [taskList, setTaskList] = useState<{}[]>([]);

  const loadTaskList = () => {
    instance
      .get('/task/list')
      .then((value) => {
        setTaskList(value.data);
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    loadTaskList();
  }, []);

  useEffect(() => {
    if (props.taskId > 0) {
      setSelectedTaskId(props.taskId);
    }
  }, [props.taskId]);

  return (
    <PopupDialog
      title={'Task info'}
      maxWidth={'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <React.Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Run</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <FormLabel id="task-repeatable-select">Repeatable</FormLabel>
              <RadioGroup row aria-labelledby="task-repeatable-select">
                <FormControlLabel value="cron" control={<Radio />} label="Cron" />
                <FormControlLabel value="once" control={<Radio />} label="Once" />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Schedule</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ScheduleSettings />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Action</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <FormLabel id="task-repeatable-select">Repeatable</FormLabel>
              <RadioGroup row aria-labelledby="task-repeatable-select">
                <FormControlLabel value="cron" control={<Radio />} label="Cron" />
                <FormControlLabel value="once" control={<Radio />} label="Once" />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    </PopupDialog>
  );
}
