import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Breakpoint } from '@mui/system';

export interface Props {
  title: string;
  children: JSX.Element | JSX.Element[];
  actions?: JSX.Element | JSX.Element[];
  onClose: () => void;
  maxWidth: Breakpoint;
}
export default function PopupDialog(props: Props) {
  return (
    <Dialog fullWidth maxWidth={props.maxWidth} open={true}>
      <DialogTitle style={{ display: 'flex' }}>
        <Typography style={{ flex: 1 }}>{props.title}</Typography>
        <IconButton edge="end" color="inherit" aria-label="close" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>{props.actions}</DialogActions>
    </Dialog>
  );
}
