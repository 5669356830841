import React, { useEffect, useState } from 'react';
import instance from '../../axios';
import PopupDialog from '../../Components/Dialog/PopupDialog';
import TextField from "@mui/material/TextField";

interface Props {
  uuid: string;
  backend: number| undefined;
  onClose: () => void;
}

export default function QrSrc(props: Props) {
  const [data, setData] = useState<string>('');

  const loadSrc = (uuid: string, backend: number) => {
    setData('')
    instance
      .get(`/oq/s2s/${backend}/qr/cart/src/${uuid}`)
      .then((value) => {
        setData(JSON.stringify(value.data.maximize, null, 4))
      })
      .catch((reason) => {
        setData(reason.toString())
      });
  };

  useEffect(() => {
    if (props.uuid && props.backend) {
      loadSrc(props.uuid, props.backend);
    }
  }, [props.uuid, props.backend]);

  return (
    <React.Fragment>
      {props.uuid && props.backend && (
        <PopupDialog
          title={props.uuid}
          onClose={() => {
            props.onClose();
          }}
          maxWidth={'sm'}
        >
          <TextField
              id="outlined-multiline-static"
              label="Request"
              multiline
              rows={20}
              fullWidth
              value={data}
          />
        </PopupDialog>
      )}
    </React.Fragment>
  );
}
