import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import AppMenu from '../../AppBar/AppMenu';
import instance from '../../axios';
import OQBackendSelect from '../OQBackendSelect';
import Box from '@mui/material/Box';

export default function OQProcessingTest() {
  const paymentCart = {
    unique: '00000000-0000-0000-0000-000000000000',
    id: 0,
    date: '0000-00-00',
    custLat: 'TEXT TEXT TEXT',
    cust: 'ТЕКСТ ТЕКСТ ТЕКСТ',
    doc: 'DOCUMENT',
    rnokpp: '0000000000',
    dept: '90Ц',
    p: '+0000000000000',
    dob: '0000-00-00'
  };
  const [presets] = useState<{ name: string; preset: any }[]>([
    {
      name: 'FV',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '7.5', code: 'FW', sum: 150 }] }
    },
    {
      name: 'БЗП0',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'БЗП0', fr: '9029', bk: '8012' }]
      }
    },
    {
      name: 'БЗП1',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.1', code: 'БЗП1', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'БЗПД0',
      preset: {
        ...paymentCart,
        serv: [
          {
            id: 0,
            tariff: '2.1.1',
            code: 'БЗПД0',
            fr: '9026',
            bk: '8017',
            cust: 'CUST',
            doc: 'DOC',
            custLat: 'CUST LAT'
          }
        ]
      }
    },
    {
      name: 'БЗПД1',
      preset: {
        ...paymentCart,
        serv: [
          {
            id: 0,
            tariff: '7.1.1',
            code: 'БЗПД1',
            fr: '9042',
            bk: '2114',
            cust: 'CUST',
            doc: 'DOC',
            custLat: 'CUST LAT'
          }
        ]
      }
    },
    {
      name: 'ПП10',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '1.1.3.1', code: 'ПП10', fr: '9061', bk: '8011' }]
      }
    },
    {
      name: 'ТП10',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '1.1.3.1', code: 'ТП10', fr: '9060', bk: '8011' }]
      }
    },
    {
      name: 'ID01',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID01', fr: '9026', bk: '8017' }]
      }
    },
    {
      name: 'ID02',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID02', fr: '9026', bk: '8017' }]
      }
    },
    {
      name: 'ID03',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID03', fr: '9026', bk: '8017' }]
      }
    },
    {
      name: 'ID04',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID04', fr: '9026', bk: '8017' }]
      }
    },
    {
      name: 'ID05',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID05', fr: '9025', bk: '8017' }]
      }
    },
    {
      name: 'ID06',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '4.2', code: 'ID06', fr: '9043', bk: '2129' }]
      }
    },
    {
      name: 'ID07',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID07', fr: '9165', bk: '8034' }]
      }
    },
    {
      name: 'ID09',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID09', fr: '9036', bk: '8029' }]
      }
    },
    {
      name: 'ID11',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.1', code: 'ID11', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'ID12',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID12', fr: '9025', bk: '8017' }]
      }
    },
    {
      name: 'ID14',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.1', code: 'ID14', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'ID15',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID15', fr: '9025', bk: '8017' }]
      }
    },
    {
      name: 'ID16',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID16', fr: '9129', bk: '8029' }]
      }
    },
    {
      name: 'ID17',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID17', fr: '9025', bk: '8017' }]
      }
    },
    {
      name: 'ID18',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.1', code: 'ID18', fr: '9003', bk: '1204' }]
      }
    },
    {
      name: 'ID19',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.1', code: 'ID19', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'СТР111',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР111', sum: 400 }] }
    },
    {
      name: 'СТР114',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР114', sum: 700 }] }
    },
    {
      name: 'СТР119',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР119', sum: 776, cust: 'CUST' }] }
    },
    {
      name: 'СТР130',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР130', sum: 1225 }] }
    },
    {
      name: 'СТР151',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР151', sum: 200 }] }
    },
    {
      name: 'СТР210',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР210', sum: 300 }] }
    },
    {
      name: 'СТР211',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР111', sum: 400 }] }
    },
    {
      name: 'СТР212',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР212', sum: 300 }] }
    },
    {
      name: 'СТР213',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР213', sum: 400, cust: 'CUST' }] }
    },
    {
      name: 'СТР214',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР214', sum: 700 }] }
    },
    {
      name: 'СТР230',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР230', sum: 980 }] }
    },
    {
      name: 'СТР251',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР251', sum: 500 }] }
    },
    {
      name: 'СТР252',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР252', sum: 650 }] }
    },
    {
      name: 'СТР260',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР260', sum: 1570 }] }
    },
    {
      name: 'СТР340',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'СТР340', sum: 1470 }] }
    },
    {
      name: 'АПЮФ5',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '2.7.3', code: 'АПЮФ5', sum: 1101 }] }
    },
    {
      name: 'АПЮФ10',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '2.7.1', code: 'АПЮФ10', sum: 701 }] }
    },
    {
      name: 'АПО6',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '2.7.3', code: 'АПО6', sum: 1290 }] }
    },
    {
      name: 'АПО15',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '2.7.1', code: 'АПО15', sum: 810 }] }
    },
    {
      name: 'АПО25',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '2.7.1', code: 'АПО25', sum: 990 }] }
    },
    {
      name: 'АПО35',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '2.7.1', code: 'АПО35', sum: 630 }] }
    },
    {
      name: 'ПН',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'ПН', sum: 500 }] }
    },
    {
      name: 'МВ51',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '5.1.1', code: 'МВ51', sum: 700 }] }
    },
    {
      name: 'МВ52',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '5.1.2', code: 'МВ52', sum: 1000 }] }
    },
    {
      name: 'ПВ',
      preset: {
        ...paymentCart,
        serv: [
          {
            id: 0,
            tariff: '7.1.3',
            code: 'ПВ',
            sum: 450,
            bk: '178087',
            bksum: 254,
            bkcode: '1111013'
          }
        ]
      }
    },
    {
      name: 'Ф3Х4',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '1.4.2', code: 'Ф3Х4', sum: 60 }] }
    },
    {
      name: 'Ф10Х15',
      preset: { ...paymentCart, serv: [{ id: 0, tariff: '1.4.3', code: 'Ф10Х15', sum: 75 }] }
    },
    {
      name: 'ADD_ZP',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'ADD_ZP', fr: '9040', bk: '2110' }] }
    },
    {
      name: 'ADD_DM',
      preset: { ...paymentCart, serv: [{ id: 0, code: 'ADD_DM', fr: '9042', bk: '2114' }] }
    },
    {
      name: 'ADD_PV',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, code: 'ADD_PV', bksum: 254, bk: '178087', bkcode: '1111013' }]
      }
    },

    {
      name: 'IZ01',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ01', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ02',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ02', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ03',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.2', code: 'IZ03', fr: '9018', bk: '5901' }]
      }
    },
    {
      name: 'IZ04',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ04', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ05',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.2', code: 'IZ05', fr: '9105', bk: '1810' }]
      }
    },
    {
      name: 'IZ06',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ06', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ07',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ07', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ08',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.2', code: 'IZ08', fr: '9002', bk: '0710' }]
      }
    },
    {
      name: 'IZ09',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ09', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ11',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ11', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ12',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.2', code: 'IZ12', fr: '9169', bk: '8026' }]
      }
    },
    {
      name: 'IZ13',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '4.3', code: 'IZ13', fr: '9043', bk: '2129' }]
      }
    },
    {
      name: 'IZ14',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ14', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ15',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '2.1.2', code: 'IZ15', fr: '9025', bk: '8017' }]
      }
    },
    {
      name: 'IZ16',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ16', fr: '9040', bk: '2110' }]
      }
    },
    {
      name: 'IZ17',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '4.3', code: 'IZ17', fr: '9043', bk: '2129' }]
      }
    },
    {
      name: 'IZ19',
      preset: {
        ...paymentCart,
        serv: [{ id: 0, tariff: '7.1.2', code: 'IZ19', fr: '9040', bk: '2110' }]
      }
    }
  ]);

  const [request, setRequest] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [acquirers, setAcquirers] = useState<{ id: number; name: string }[]>([]);
  const [selectedAcquirer, setSelectedAcquirer] = useState<string | number>(0);
  const [selectedPresetName, setSelectedPresetName] = useState<string>('');
  const [isRequestReady, setRequestReady] = useState<boolean>(false);

  const [backendSelected, setBackendSelected] = useState<number>(0);
  const loadPaymentProviders = (backend: number) => {
    instance
      .get(`/oq/s2s/${backend}/payment-providers/payment-terminals`)
      .then((value) => {
        console.log(value.data);
        setAcquirers(value.data);
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    loadPaymentProviders(backendSelected);
  }, [backendSelected]);

  useEffect(() => {
    try {
      JSON.parse(request);
      setRequestReady(true);
    } catch (e) {
      setRequestReady(false);
    }
  }, [request]);

  const test = (backend: number, selectedAcquirer: number | string, paymentData: string) => {
    axios
      .post(`/oq/s2s/${backend}/test-processing/${selectedAcquirer}`, paymentData)
      .then((res) => {
        setResponse(JSON.stringify(res.data, null, 4));
      });
  };

  return (
    <React.Fragment>
      <AppMenu header={'Processing test'} />
      <CssBaseline />
      <Box sx={{ mx: 1, mt: 1 }}>
        <OQBackendSelect
          setBackendId={(v) => {
            setBackendSelected(v);
          }}
        />
      </Box>
      <Box sx={{ mx: 1 }}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id="demo-simple-select-label">Preset</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedPresetName}
            label="Preset"
            onChange={(e) => {
              setSelectedPresetName(e.target.value);
              setRequest(
                JSON.stringify(presets.find((v) => v.name == e.target.value)!.preset, null, 4)
              );
            }}
          >
            {presets.map((value, index) => (
              <MenuItem value={value.name}>{value.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id="demo-simple-select-label">Processing</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedAcquirer.toString()}
            label="Processing"
            onChange={(e) => {
              setSelectedAcquirer(e.target.value);
            }}
          >
            {acquirers.map((value, index) => (
              <MenuItem value={value.id}>{value.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ mt: 1 }}>
          <Button
            variant="outlined"
            fullWidth
            color={'success'}
            size="large"
            disabled={selectedAcquirer <= 0 || !isRequestReady}
            onClick={() => {
              test(backendSelected, selectedAcquirer, JSON.parse(request));
            }}
          >
            Send
          </Button>
        </Box>

        <Grid container spacing={1} sx={{ mt: 0 }}>
          <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
            <TextField
              id="outlined-multiline-static"
              label="Request"
              multiline
              rows={20}
              fullWidth
              value={request}
              onChange={(event) => {
                setRequest(event.target.value);
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
            <TextField
              id="outlined-multiline-static"
              label="Response"
              multiline
              rows={20}
              fullWidth
              value={response}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
