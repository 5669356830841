import { useEffect, useState } from 'react';
import instance from '../axios';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export enum PersonalDataMaskingLevel {
  All = 0,
  L1 = 1,
  L2 = 2,
  L3 = 3,
  L4 = 4,
  NoMasking = 100
}

interface Props {
  user: number;
  backend: number;
}

export default function UserQRBackendPersonalDataLevel(props: Props) {
  const [currentUserPersonalDataLevel, setCurrentUserPersonalDataLevel] = useState<number>(0);
  const [currentUserPersonalDataLevelSelected, setCurrentUserPersonalDataLevelSelected] =
    useState<number>();

  const loadPDLevel = (backend: number, user: number) => {
    instance
      .get(`/oq/pd-level/user/${backend}/${user}`)
      .then((value) => {
        setCurrentUserPersonalDataLevel(value.data.level);
      })
      .catch((reason) => {});
  };

  const setPDLevel = (backend: number, user: number, level: number) => {
    instance
      .post(`/oq/pd-level/user/${backend}/${user}`, { level })
      .then((value) => {})
      .catch((reason) => {});
  };

  useEffect(() => {
    loadPDLevel(props.backend, props.user);
  }, [props]);

  useEffect(() => {
    if (currentUserPersonalDataLevelSelected !== undefined) {
      setCurrentUserPersonalDataLevel(currentUserPersonalDataLevelSelected);
      setPDLevel(props.backend, props.user, currentUserPersonalDataLevelSelected);
    }
  }, [currentUserPersonalDataLevelSelected]);

  return (
    <FormControl fullWidth>
      <InputLabel id="label">Personal data masking level</InputLabel>
      <Select
        labelId="label"
        value={currentUserPersonalDataLevel || 0}
        label="Personal data masking level"
        onChange={(e) => {
          setCurrentUserPersonalDataLevelSelected(parseInt(e.target.value.toString()));
        }}
      >
        {(Object.values(PersonalDataMaskingLevel).filter((v) => isNaN(Number(v))) as number[]).map(
          (value, index) => (
            <MenuItem key={`sv-${index}`} value={parseInt(PersonalDataMaskingLevel[value])}>
              {value}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
