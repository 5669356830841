import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  CircularProgress,
  Grid,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppMenu from '../../AppBar/AppMenu';
import instance from '../../axios';
import OQBackendSelect from '../OQBackendSelect';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Button from '@mui/material/Button';
import PaymentInfo from './PaymentInfo';

export interface OnlineQrBackendItem {
  id: number;
  unique: string;
  date: string;
  cust: string;
  custLat: string;
  p: string;
  dob: string;
  doc: string;
  rnokpp: string;
}

const columns: GridColDef[] = [
  { field: 'unique', headerName: 'UUID', width: 300 },
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'dept', headerName: 'Dept.', width: 90 },
  { field: 'date', headerName: 'Date', width: 110 },
  { field: 'custCyr', headerName: 'Customer', width: 250 },
  { field: 'custLat', headerName: 'Customer', width: 250 },
  { field: 'phone', headerName: 'Phone', width: 150 },
  { field: 'dob', headerName: 'Birthday', width: 100 },
  { field: 'doc', headerName: 'Document', width: 120 },
  { field: 'rnokpp', headerName: 'Vat number', width: 120 }
];

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.Mui-expanded': {
    marginTop: 0
  }
}));

export default function OQCartList() {
  const [userList, setUserList] = useState<OnlineQrBackendItem[]>([]);

  const [backendSelected, setBackendSelected] = useState<number>(0);

  const [createDateBegin, setCreateDateBegin] = useState<Date | undefined>(new Date());
  const [createDateEnd, setCreateDateEnd] = useState<Date | undefined>(new Date());

  const [cartID, setCartID] = useState<string>('');
  const [cartUUID, setCartUUID] = useState<string>('');

  const [customerCyr, setCustomerCyr] = useState<string>('');
  const [customerLat, setCustomerLat] = useState<string>('');
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const [cartDept, setCartDept] = useState<string>('');

  const [isDataLoading, setDataLoading] = useState<boolean>(false);

  const [needDataLoad, setNeedDataLoad] = useState<boolean>(false);

  const [selectedUUID, setSelectedUUID] = useState<string>('');

  const loadUserList = (
    backendId: number,
    begin: string,
    end: string,
    id?: string,
    uuid?: string,
    customerCyr?: string,
    customerLat?: string,
    customerPhone?: string,
    cartDept?: string
  ) => {
    setDataLoading(true);
    instance
      .post(`/oq/s2s/${backendId}/qr/cart`, {
        begin,
        end,
        id,
        uuid,
        customerCyr,
        customerLat,
        phone: customerPhone,
        dept: cartDept
      })
      .then((value) => {
        setUserList(value.data);
        setDataLoading(false);
      })
      .catch((reason) => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    if (needDataLoad) {
      setNeedDataLoad(false);
      if (backendSelected > 0 && needDataLoad) {
        loadUserList(
          backendSelected,
          createDateBegin!.toISOString(),
          createDateEnd!.toISOString(),
          cartID || undefined,
          cartUUID || undefined,
          customerCyr || undefined,
          customerLat || undefined,
          customerPhone || undefined,
          cartDept || undefined
        );
      }
    }
  }, [needDataLoad]);

  useEffect(() => {
    clearFilter();
  }, [backendSelected]);

  const clearFilter = () => {
    setCreateDateBegin(new Date());
    setCreateDateEnd(new Date());
    setCartID('');
    setCartUUID('');
    setCustomerCyr('');
    setCustomerLat('');
    setCustomerPhone('');
    setCartDept('');
    setNeedDataLoad(true);
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppMenu header={'Online qr'} />
        <CssBaseline />
        <Box sx={{ mx: 1, mt: 1 }}>
          <OQBackendSelect
            setBackendId={(v) => {
              setBackendSelected(v);
            }}
          />
        </Box>
        <Box sx={{ mx: 1, mt: 1 }}>
          <StyledAccordion disabled={backendSelected === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <MobileDatePicker
                      closeOnSelect
                      label="Create begin"
                      inputFormat="YYYY-DD-MM"
                      value={createDateBegin}
                      onChange={(e) => {
                        setCreateDateBegin(e ? new Date(e) : undefined);
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <MobileDatePicker
                      closeOnSelect
                      label="Create end"
                      inputFormat="YYYY-DD-MM"
                      value={createDateEnd}
                      onChange={(e) => {
                        setCreateDateEnd(e ? new Date(e) : undefined);
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={2} lg={2} xl={1}>
                    <TextField
                      required
                      fullWidth
                      label="Cart ID"
                      value={cartID}
                      onChange={(e) => {
                        setCartID(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={2} lg={2} xl={1}>
                    <TextField
                      required
                      fullWidth
                      label="Cart UUID"
                      value={cartUUID}
                      onChange={(e) => {
                        setCartUUID(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <TextField
                      required
                      fullWidth
                      label="Customer cyr"
                      value={customerCyr}
                      onChange={(e) => {
                        setCustomerCyr(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <TextField
                      required
                      fullWidth
                      label="Customer lat"
                      value={customerLat}
                      onChange={(e) => {
                        setCustomerLat(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <TextField
                      required
                      fullWidth
                      label="Customer phone"
                      value={customerPhone}
                      onChange={(e) => {
                        setCustomerPhone(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <TextField
                      required
                      fullWidth
                      label="Cart department"
                      value={cartDept}
                      onChange={(e) => {
                        setCartDept(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </AccordionDetails>
            <AccordionActions>
              <Button
                color={'inherit'}
                variant="text"
                onClick={() => {
                  clearFilter();
                }}
                disabled={isDataLoading}
              >
                Clear
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setNeedDataLoad(true);
                }}
                disabled={isDataLoading}
              >
                Apply
              </Button>
            </AccordionActions>
          </StyledAccordion>
        </Box>
        {backendSelected > 0 && (
          <React.Fragment>
            {isDataLoading && (
              <Box sx={{ mt: 2, display: 'flex', width: '100%', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
            {!isDataLoading && userList.length > 0 && (
              <Box sx={{ m: 1, flex: 1, width: 'auto' }}>
                <DataGrid
                  onRowClick={(params, event) => {
                    setSelectedUUID(params.row.unique);
                  }}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSelector
                  disableDensitySelector
                  autoPageSize
                  disableSelectionOnClick
                  rows={userList}
                  columns={columns}
                  getRowId={(row: any) => row.unique}
                />
              </Box>
            )}
            {!isDataLoading && userList.length === 0 && (
              <Alert sx={{ m: 1 }} severity="info">
                No data. Change the filter criteria
              </Alert>
            )}
          </React.Fragment>
        )}
      </div>
      {selectedUUID && (
        <PaymentInfo
          backend={backendSelected}
          uuid={selectedUUID}
          onClose={() => {
            setSelectedUUID('');
          }}
        />
      )}
    </React.Fragment>
  );
}
