// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCV5jBYl_suso2U5CPfa1Fw_mh7eGU4E28',
  authDomain: 'pa-airas.firebaseapp.com',
  projectId: 'pa-airas',
  storageBucket: 'pa-airas.appspot.com',
  messagingSenderId: '436627103990',
  appId: '1:436627103990:web:b421480916ca18c9e38cd7'
};

// Initialize Firebase
initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BD0upb2bJkC3HNyO7uSNpUyPgMVe5UAE9o-WzXcDJ2YITUKnLg4cqV7ZHHXDVqKMQUR4AE_qkmfQB9gdI4m39LU'
  });
};
