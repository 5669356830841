import { PaymentTransactionRow } from './Interfaces/PaymentTransactionRow';
import PopupDialog from '../Components/Dialog/PopupDialog';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { UIPermissionContext } from '../App';
import { UserPermissionList } from '../enums/UserPermissionList';
import Button from '@mui/material/Button';
import instance from '../axios';
import PaymentInfo from '../OQ/Cart/PaymentInfo';

export type TransactionAction = 'none' | 'delete-full' | 'delete-schedule' | 'restore';
interface PresetItem {
  name: string;
  template: any;
}
function DocumentPDF(props: { onClose: () => void; data: any }) {
  const [selectedPreset, setSelectedPreset] = useState<string>();
  const [presets] = useState<PresetItem[]>([
    {
      name: 'DP Client',
      template: [
        {
          type: 'item-text',
          value: 'Квитанція про зареєстроване платіжне доручення',
          font: 'Roboto-Black',
          fontSize: 12
        },
        {
          type: 'item-empty',
          height: 5
        },
        {
          type: 'item-text',
          target: 'docid',
          font: 'Roboto-Black',
          fontSize: 12,
          valueBeforeString: '№'
        },
        {
          type: 'item-empty',
          height: 10
        },
        {
          type: 'item-text-doubled',
          header: 'Дата та час здійснення операції',
          headerFont: 'Roboto-Medium',
          target: 'datetime',
          font: 'Roboto-Medium',
          fontSize: 11
        },
        {
          type: 'item-empty',
          height: 10
        },
        {
          type: 'item-column-container',
          direction: 'vertical',
          borderWidth: 1,
          fillColor: '#E1F5FE',
          col: [
            {
              type: 'item-column',
              verticalAlignment: 'top',
              padding: {
                l: 10,
                r: 10,
                t: 5
              },
              item: {
                type: 'item-text',
                value: 'Сума платежу',
                font: 'Roboto-Medium',
                fontSize: 11
              }
            },
            {
              type: 'item-column',
              item: {
                type: 'item-empty',
                height: 10
              }
            },
            {
              type: 'item-column',
              verticalAlignment: 'top',
              item: {
                type: 'item-text',
                target: 'receiptAmount',
                font: 'Roboto-Black',
                fontSize: 34,
                targetAfterString: 'receiptCurrencyLocalString',
                valueAfterString: 'грн'
              }
            },
            {
              type: 'item-column',
              item: {
                type: 'item-empty',
                height: 11
              }
            },
            {
              type: 'item-column',
              verticalAlignment: 'top',
              padding: {
                l: 10,
                r: 10,
                b: 5
              },
              item: {
                type: 'item-text',
                target: 'receiptAmountString',
                font: 'Roboto-Light',
                fontSize: 11
              }
            }
          ]
        },
        {
          type: 'item-column-container',
          direction: 'vertical',
          borderWidth: 1,
          fillColor: '#E1F5FE',
          col: [
            {
              type: 'item-column',
              verticalAlignment: 'top',
              horizontalAlignment: 'left',
              padding: {
                l: 10,
                r: 10,
                t: 5,
                b: 5
              },
              item: {
                type: 'item-text-doubled',
                header: 'Призначення платежу',
                headerFont: 'Roboto-Light',
                target: 'receiptPurpose',
                font: 'Roboto-Medium',
                fontSize: 11
              }
            }
          ]
        },
        {
          type: 'item-empty',
          height: 10
        },
        {
          type: 'item-text-doubled',
          header: 'Платник',
          headerFont: 'Roboto-Light',
          target: 'payer.payer',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'Банк Платника',
          headerFont: 'Roboto-Light',
          target: 'payer.payerBank.bankName',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'МФО Банку Платника',
          headerFont: 'Roboto-Light',
          target: 'payer.payerBank.bankMFO',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'ЄДРПОУ Банку Платника',
          headerFont: 'Roboto-Light',
          target: 'payer.payerBank.bankEDRPOU',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'Адреса Банку Платника',
          headerFont: 'Roboto-Light',
          target: 'payer.payerBank.bankAddress',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-empty',
          height: 10
        },
        {
          type: 'item-text-doubled',
          header: 'Отримувач',
          headerFont: 'Roboto-Light',
          target: 'receiver.receiver',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'Код за ЄДРПОУ Отримувача',
          headerFont: 'Roboto-Light',
          target: 'receiver.receiverEDRPOU',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'Банк Отримувача',
          headerFont: 'Roboto-Light',
          target: 'receiver.receiverBank.bankName',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'МФО Банку Отримувача',
          headerFont: 'Roboto-Light',
          target: 'receiver.receiverBank.bankMFO',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-text-doubled',
          header: 'Поточний рахунок Отримувача',
          headerFont: 'Roboto-Light',
          target: 'receiver.receiverBankAccount',
          font: 'Roboto-Medium',
          fontSize: 11,
          horizontalAlignment: 'left'
        },
        {
          type: 'item-empty',
          height: 10
        },
        {
          type: 'item-column-container',
          direction: 'horizontal',
          fillColor: '#E1F5FE',
          col: [
            {
              type: 'item-column',
              width: 80,
              verticalAlignment: 'top',
              item: {
                type: 'item-image',
                target: 'receiptUrl',
                height: 80,
                width: 80
              }
            },
            {
              type: 'item-column',
              padding: {
                l: 10
              },
              item: {
                type: 'item-column-container',
                direction: 'vertical',
                col: [
                  {
                    type: 'item-column',
                    verticalAlignment: 'top',
                    item: {
                      type: 'item-text-doubled',
                      header: 'Дата та час авторизації',
                      headerFont: 'Roboto-Light',
                      target: 'receiptDatetime',
                      font: 'Roboto-Medium',
                      fontSize: 11,
                      horizontalAlignment: 'left'
                    }
                  },
                  {
                    type: 'item-column',
                    verticalAlignment: 'top',
                    item: {
                      type: 'item-text-doubled',
                      header: 'Код авторизації',
                      headerFont: 'Roboto-Light',
                      target: 'acquire.authorizationCode',
                      font: 'Roboto-Medium',
                      fontSize: 11,
                      horizontalAlignment: 'left'
                    }
                  },
                  {
                    type: 'item-column',
                    verticalAlignment: 'top',
                    item: {
                      type: 'item-text-doubled',
                      header: 'Номер терміналу',
                      headerFont: 'Roboto-Light',
                      target: 'terminalid',
                      font: 'Roboto-Medium',
                      fontSize: 11,
                      horizontalAlignment: 'left'
                    }
                  },
                  {
                    type: 'item-column',
                    verticalAlignment: 'top',
                    item: {
                      type: 'item-text-doubled',
                      header: 'Номер операції',
                      headerFont: 'Roboto-Light',
                      target: 'transname',
                      font: 'Roboto-Medium',
                      fontSize: 11,
                      horizontalAlignment: 'left'
                    }
                  }
                ]
              }
            }
          ]
        },
        {
          type: 'item-empty',
          height: 5
        },
        {
          type: 'item-text',
          value: 'Операція з використанням електронного платіжного засобу',
          font: 'Roboto-Medium',
          fontSize: 10
        }
      ]
    }
  ]);

  const makeDocument = (template: any, data: any) => {
    instance
      .post(`/pdf`, { template, data }, { responseType: 'blob' })
      .then((value) => {
        const blobData = new Blob([value.data], { type: 'application/pdf' });
        // create temp download url
        const pdfObjectUrl = window.URL.createObjectURL(blobData);

        // Open the PDF in a new tab
        const newTab = window.open(pdfObjectUrl, '_blank');
        if (newTab) {
          newTab.focus();
        } else {
          console.error('Popup blocker prevented opening the new tab.');
        }
        // // open pdf file on new tab
        // window.open(downloadUrl, '__blank');
        //
        // // remove temp url
        // window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((reason) => {});
  };

  return (
    <PopupDialog
      title={`Print PDF`}
      onClose={() => {
        props.onClose();
      }}
      maxWidth={'sm'}
      actions={
        <Button
          color={'success'}
          onClick={() => {
            const preset = presets.find((v) => v.name == selectedPreset);
            if (preset) {
              makeDocument(preset.template, props.data);
            }
          }}
          disabled={!selectedPreset}
        >
          Print
        </Button>
      }
    >
      <FormControl fullWidth sx={{ mt: 1 }}>
        <InputLabel id="presetLabel">Preset</InputLabel>
        <Select
          labelId="presetLabel"
          value={selectedPreset}
          label="Preset"
          onChange={(e) => {
            setSelectedPreset(e.target.value.toString());
          }}
        >
          {presets.map((value, index) => (
            <MenuItem key={`sv-${index}`} value={value.name}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </PopupDialog>
  );
}

function TransactionActions(props: {
  data: PaymentTransactionRow;
  backend: number;
  terminal: string;
  needReload: () => void;
}) {
  const uiPermission = React.useContext(UIPermissionContext);

  const [transactionAction, setTransactionAction] = useState<TransactionAction>('none');
  const [transactionActionProcess, setTransactionActionProcess] = useState<boolean>(false);

  const [dialogPDF, setDialogPDF] = useState<boolean>(false);

  const isPendingDeletion = props.data.deleted == '2';
  const isDeleted = props.data.deleted == '1';
  const isPos = props.data.pos == '1';
  const backend = props.backend;

  // const [isWaitActionResult, setWaitActionResult] = useState<boolean>(false);

  const [selectedUUID, setSelectedUUID] = useState<string>('');

  useEffect(() => {
    setTransactionAction('none');
    setTransactionActionProcess(false);
  }, []);

  const processTransactionAction = (
    action: TransactionAction,
    backendId: number,
    terminal: string,
    document: string,
    amount: number,
    isCashless: boolean
  ) => {
    setTransactionActionProcess(true);
    let actionUrl = undefined;
    let actionData = undefined;
    switch (action) {
      case 'delete-full':
        actionUrl = 'operations/pos/delete-full';
        actionData = { terminal, document, amount };
        break;
      case 'delete-schedule':
        actionUrl = 'operations/pos/delete-schedule';
        actionData = { terminal, document, amount };
        break;
      case 'restore':
        actionUrl = 'operations/pos/restore';
        actionData = { terminal, document, amount };
        break;
      case 'none':
      default:
    }
    instance
      .post(`/pa/${backendId}/${actionUrl}`, actionData)
      .then((value) => {
        setTransactionAction('none');
        setTransactionActionProcess(false);
        props.needReload();
      })
      .catch((reason) => {
        setTransactionAction('none');
        setTransactionActionProcess(false);
        props.needReload();
      });
  };

  return (
    <React.Fragment>
      {selectedUUID && (
        <PaymentInfo
          uuid={selectedUUID}
          onClose={() => {
            setSelectedUUID('');
          }}
        />
      )}
      {(uiPermission.check([UserPermissionList.AllowScheduleDeletePosDocuments]) ||
        uiPermission.check([UserPermissionList.AllowFullDeletePosDocuments])) && (
        <React.Fragment>
          <Button
            onClick={() => {
              setDialogPDF(true);
            }}
            color={'success'}
          >
            PDF
          </Button>
          {props.data.uniques && (
            <Button
              onClick={() => {
                setSelectedUUID(props.data.uniques);
              }}
            >
              Find QR
            </Button>
          )}
          {(isDeleted || isPendingDeletion) && (
            <Button
              disabled={transactionActionProcess}
              color={'success'}
              onClick={() => {
                setTransactionAction('restore');
              }}
            >
              Restore
            </Button>
          )}
          {!isDeleted &&
            !isPendingDeletion &&
            uiPermission.check([UserPermissionList.AllowFullDeletePosDocuments]) && (
              <Button
                disabled={transactionActionProcess}
                color={'error'}
                onClick={() => {
                  setTransactionAction('delete-full');
                  // fullDelete(backend, props.terminal, props.data.docid, props.data.amount)
                }}
              >
                Full delete
              </Button>
            )}
          {!isDeleted &&
            !isPendingDeletion &&
            uiPermission.check([UserPermissionList.AllowScheduleDeletePosDocuments]) && (
              <Button
                disabled={transactionActionProcess}
                color={'warning'}
                onClick={() => {
                  setTransactionAction('delete-schedule');
                  // scheduleDelete(backend, props.terminal, props.data.docid, props.data.amount)
                }}
              >
                Schedule removal
              </Button>
            )}
        </React.Fragment>
      )}
      {transactionAction !== 'none' && (
        <PopupDialog
          title={`Confirm ${transactionAction}`}
          onClose={() => {
            setTransactionAction('none');
          }}
          maxWidth={'sm'}
          actions={
            <Button
              color={'warning'}
              onClick={() => {
                processTransactionAction(
                  transactionAction,
                  backend,
                  props.terminal,
                  props.data.docid,
                  props.data.amount,
                  true
                );
              }}
              disabled={transactionActionProcess}
            >
              Confirm
            </Button>
          }
        >
          <p>Check you choice again</p>
        </PopupDialog>
      )}
      {dialogPDF && (
        <DocumentPDF
          data={props.data}
          onClose={() => {
            setDialogPDF(false);
          }}
        />
      )}
    </React.Fragment>
  );
}

interface Props {
  terminal: string;
  backend: number;
  data: PaymentTransactionRow;
  needReload: () => void;
  onClose: () => void;
}

export default function PAPaymentActionDialog(props: Props) {
  return (
    <React.Fragment>
      {props.data && (
        <PopupDialog
          title=""
          onClose={() => {
            props.onClose();
          }}
          maxWidth={'sm'}
          actions={
            <TransactionActions
              terminal={props.terminal}
              backend={props.backend}
              data={props.data}
              needReload={props.needReload}
            />
          }
        >
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                disabled
                label="purpose"
                variant="outlined"
                fullWidth
                multiline
                value={props.data.purpose}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                disabled
                label="debitname"
                variant="outlined"
                fullWidth
                value={props.data.debitname}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextField
                disabled
                label="datetime"
                variant="outlined"
                fullWidth
                value={props.data.datetime}
              />
            </Grid>
            <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
              <TextField
                disabled
                label="amount"
                variant="outlined"
                fullWidth
                value={props.data.amount}
              />
            </Grid>
            <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
              <TextField
                disabled
                label="comis"
                variant="outlined"
                fullWidth
                value={props.data.comis}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
              <TextField
                disabled
                label="transname"
                variant="outlined"
                fullWidth
                value={props.data.transname}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
              <TextField
                disabled
                label="transactionsid"
                variant="outlined"
                fullWidth
                value={props.data.transactionsid}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
              <TextField
                disabled
                label="docid"
                variant="outlined"
                fullWidth
                value={props.data.docid}
              />
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
              <TextField
                disabled
                label="terminalid"
                variant="outlined"
                fullWidth
                value={props.terminal}
              />
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
              <TextField
                disabled
                label="bonds"
                variant="outlined"
                fullWidth
                value={props.data.bonds}
              />
            </Grid>

            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="cash" variant="outlined" fullWidth value={props.data.cash}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="credit" variant="outlined" fullWidth value={props.data.credit}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="creditbankname" variant="outlined" fullWidth value={props.data.creditbankname}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="creditmfo" variant="outlined" fullWidth value={props.data.creditmfo}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="creditname" variant="outlined" fullWidth value={props.data.creditname}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="creditokpo" variant="outlined" fullWidth value={props.data.creditokpo}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="debit" variant="outlined" fullWidth value={props.data.debit}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="debitbankname" variant="outlined" fullWidth value={props.data.debitbankname}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="debitmfo" variant="outlined" fullWidth value={props.data.debitmfo}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="debitokpo" variant="outlined" fullWidth value={props.data.debitokpo}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="deleted" variant="outlined" fullWidth value={props.data.deleted}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="deletedresone" variant="outlined" fullWidth value={props.data.deletedresone}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="docconfirmdate" variant="outlined" fullWidth value={props.data.docconfirmdate}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="docstate" variant="outlined" fullWidth value={props.data.docstate}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="doctype" variant="outlined" fullWidth value={props.data.doctype}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="doctypes" variant="outlined" fullWidth value={props.data.doctypes}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="parentid" variant="outlined" fullWidth value={props.data.parentid}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="paymentconfirmdate" variant="outlined" fullWidth value={props.data.paymentconfirmdate}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="paymentid" variant="outlined" fullWidth value={props.data.paymentid}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="paymentstate" variant="outlined" fullWidth value={props.data.paymentstate}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="platid" variant="outlined" fullWidth value={props.data.platid}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="pos" variant="outlined" fullWidth value={props.data.pos}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="purpose" variant="outlined" fullWidth value={props.data.purpose}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="reestrid" variant="outlined" fullWidth value={props.data.reestrid}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="rest" variant="outlined" fullWidth value={props.data.rest}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="serviceid" variant="outlined" fullWidth value={props.data.serviceid}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="statuscode" variant="outlined" fullWidth value={props.data.statuscode}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="statusdetail" variant="outlined" fullWidth value={props.data.statusdetail}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3} md={3} lg={3} xl={3}>*/}
            {/*    <TextField disabled label="uniques" variant="outlined" fullWidth value={props.data.uniques}/>*/}
            {/*</Grid>*/}
          </Grid>
        </PopupDialog>
      )}
    </React.Fragment>
  );
}
