import React, { useEffect, useState } from 'react';
import PopupDialog from '../Components/Dialog/PopupDialog';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import PABackendSelect from './PABackendSelect';
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AppMenu from '../AppBar/AppMenu';
import moment from 'moment';
import instance from '../axios';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import PageviewIcon from '@mui/icons-material/Pageview';

import AddIcon from '@mui/icons-material/Add';
import PATerminalSelect from './PATerminalSelect';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import PARegistryUploadJob from './PARegistryUploadJob';
import { pink } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

export interface JobStatus {
  isCompleted: boolean;
  isStuck: boolean;
  isActive: boolean;
  isPaused: boolean;
  isDelayed: boolean;
  isFailed: boolean;
  isWaiting: boolean;
}

export function JobStatusAvatar(props: { jobStatus: JobStatus; progress: number }) {
  function getIcon(status: JobStatus, progress: number) {
    if (!status) return 'NA';
    if (status.isCompleted) return <DoneIcon />;
    if (status.isWaiting) return <PauseCircleOutlineIcon />;
    if (status.isPaused) return <PauseCircleOutlineIcon />;

    if (status.isStuck) return <PauseCircleOutlineIcon />;
    if (status.isActive) return progress;
    if (status.isFailed) return <ClearIcon />;
    if (status.isDelayed) return <PauseCircleOutlineIcon />;
  }

  function getColor(status: JobStatus) {
    if (!status) return '#fafafa';
    if (status.isCompleted) return '#2E7D32';
    if (status.isWaiting) return '#F9A825';
    if (status.isPaused) return '#F9A825';

    if (status.isStuck) return '#37474F';
    if (status.isActive) return '#1565C0';
    if (status.isFailed) return '#C62828';
    if (status.isDelayed) return '#37474F';
  }

  return (
    <Avatar sx={{ bgcolor: getColor(props.jobStatus) }}>
      {getIcon(props.jobStatus, props.progress)}
    </Avatar>
  );
}

export default function PARegistryList() {
  const [registryList, setRegistryList] = useState([]);
  // const [transactionListFiltred, setTransactionListFiltred] = useState<PaymentTransactionRow[]>([])

  const [backendSelected, setBackendSelected] = useState<number>(0);

  const [dateBegin, setDateBegin] = useState<Date | undefined>();
  const [dateEnd, setDateEnd] = useState<Date | undefined>();

  const [isDataLoading, setDataLoading] = useState<boolean>(false);

  const [selectedJobId, setSelectedJobId] = useState<number>(0);

  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);

  const [createTaskIsPosTerminal, setCreateTaskIsPosTerminal] = useState<boolean>(false);
  const [createTaskIsAllTerminal, setCreateTaskIsAllTerminal] = useState<boolean>(false);
  const [createTaskIsContractual, setCreateTaskIsContractual] = useState<boolean>(false);
  const [createTaskIsBeforeCollection, setCreateTaskIsBeforeCollection] = useState<boolean>(false);

  const [terminalSelected, setTerminalSelected] = useState<string>('');

  // const [selectedDocument, setSelectedDocument] = useState<PaymentTransactionRow | undefined>(undefined)

  const loadRegistryList = (backendId: number) => {
    setDataLoading(true);
    instance
      .get(`/pa/${backendId}/registry/upload-jobs`)
      .then((value) => {
        console.log(value.data);
        // if (selectedDocument) {
        //     setSelectedDocument(value.data.find((v: any)=>v.docid == selectedDocument.docid))
        // }
        // setTransactionList(value.data)
        setRegistryList(value.data);
        setDataLoading(false);
      })
      .catch((reason) => {
        setDataLoading(false);
      });
  };

  const createRegistryJob = (
    backendId: number,
    datetime: string,
    terminal: string,
    isBeforeCollection: boolean,
    isContractual: boolean,
    isPosTerminal: boolean
  ) => {
    setDataLoading(true);
    instance
      .post(`/pa/${backendId}/registry/create`, {
        datetime,
        terminal,
        isBeforeCollection,
        isContractual,
        isPosTerminal
      })
      .then((value) => {
        console.log(value.data);
        loadRegistryList(backendSelected);
        setFilterOpen(false);
      })
      .catch((reason) => {
        setFilterOpen(false);
      });
  };

  useEffect(() => {
    loadRegistryList(backendSelected);
  }, []);

  useEffect(() => {
    clearFilter();
  }, [backendSelected]);

  const clearFilter = () => {
    setDateBegin(moment(new Date()).startOf('day').toDate());
    setDateEnd(moment(new Date()).endOf('day').toDate());
  };

  return (
    <React.Fragment>
      {isFilterOpen && (
        <PopupDialog
          title="Select params to create job"
          onClose={() => {
            setFilterOpen(false);
          }}
          maxWidth={'sm'}
          actions={
            <React.Fragment>
              <Button
                variant="text"
                onClick={() => {
                  createRegistryJob(
                    backendSelected,
                    moment(dateBegin!).format('YYYY-MM-DDTHH:mm:ss'),
                    terminalSelected,
                    createTaskIsBeforeCollection,
                    createTaskIsContractual,
                    createTaskIsPosTerminal
                  );
                }}
                // disabled={isDataLoading}
              >
                Create upload job
              </Button>
            </React.Fragment>
          }
        >
          <Box sx={{ mx: 1, mt: 1 }}>
            <PABackendSelect
              backend={backendSelected}
              setBackendId={(v) => {
                setBackendSelected(v);
              }}
            />
          </Box>
          <Box sx={{ mx: 1, mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <PATerminalSelect
                    allowSelectAll
                    terminal={terminalSelected}
                    disabled={!backendSelected || createTaskIsAllTerminal}
                    backend={backendSelected}
                    setTerminalId={(v) => {
                      setTerminalSelected(v);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  {createTaskIsPosTerminal ? (
                    <MobileDatePicker
                      disabled={!backendSelected}
                      closeOnSelect
                      label="Date"
                      inputFormat="DD-MM-YYYY"
                      value={dateBegin}
                      onChange={(e) => {
                        setDateBegin(e ? new Date(e) : undefined);
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                    />
                  ) : (
                    <MobileDateTimePicker
                      disabled={!backendSelected}
                      closeOnSelect
                      label="Datetime"
                      inputFormat="DD-MM-YYYY HH:mm"
                      value={dateBegin}
                      onChange={(e) => {
                        setDateBegin(e ? new Date(e) : undefined);
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                    />
                  )}
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>

          <Box sx={{ mx: 1, mt: 1 }}>
            <div style={{ display: 'flex', verticalAlign: 'middle' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={createTaskIsPosTerminal}
                    onChange={(e) => setCreateTaskIsPosTerminal(e.target.checked)}
                  />
                }
                label="POS"
                disabled={!backendSelected}
              />
            </div>
          </Box>
          <Box sx={{ mx: 1, mt: 1 }}>
            <div style={{ display: 'flex', verticalAlign: 'middle' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={createTaskIsContractual}
                    onChange={(e) => setCreateTaskIsContractual(e.target.checked)}
                  />
                }
                label="Contractual"
                disabled={!backendSelected}
              />
            </div>
          </Box>
          <Box sx={{ mx: 1, mt: 1 }}>
            <div style={{ display: 'flex', verticalAlign: 'middle' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={createTaskIsBeforeCollection}
                    onChange={(e) => setCreateTaskIsBeforeCollection(e.target.checked)}
                  />
                }
                label="Before collection"
                disabled={!backendSelected}
              />
            </div>
          </Box>
        </PopupDialog>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppMenu
          additionalLeftActionButton={
            <IconButton
              onClick={() => {
                setFilterOpen(true);
              }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <AddIcon />
            </IconButton>
          }
        />
        <CssBaseline />
        <Paper sx={{ m: 2 }}>
          <List>
            {registryList.map((v: any, i) => {
              const labelId = `checkbox-list-label-${i}`;

              return (
                <ListItem
                  key={i}
                  secondaryAction={
                    <>
                      <IconButton
                        aria-label="comments"
                        onClick={() => {
                          setSelectedJobId(v.id);
                        }}
                      >
                        <TextSnippetIcon />
                      </IconButton>
                      {/*<IconButton color={'error'} edge="end" aria-label="comments">*/}
                      {/*    <DeleteIcon />*/}
                      {/*</IconButton>*/}
                    </>
                  }
                >
                  <ListItemAvatar>
                    <JobStatusAvatar jobStatus={v.status} progress={v.progress} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`Upload job on ${v.backend.name}`}
                    secondary={`Created ${v.createdDatetime}`}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </div>
      {selectedJobId > 0 && (
        <PARegistryUploadJob
          selectedJobId={selectedJobId}
          onClose={() => {
            setSelectedJobId(0);
          }}
        />
      )}
    </React.Fragment>
  );
}
