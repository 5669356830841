import {
  Accordion,
  Checkbox,
  darken,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  lighten,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Button from '@mui/material/Button';
import AppMenu from '../AppBar/AppMenu';
import instance from '../axios';
import PABackendSelect from './PABackendSelect';
import PATerminalSelect from './PATerminalSelect';
import moment from 'moment';
import { PaymentTransactionRow } from './Interfaces/PaymentTransactionRow';
import PAPaymentActionDialog from './PAPaymentActionDialog';
import TuneIcon from '@mui/icons-material/Tune';
import PopupDialog from '../Components/Dialog/PopupDialog';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        )
      }
    }
  },
  '& .super-app-theme--MainDocument': {
    backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        )
      }
    }
  },
  '& .super-app-theme--PendingDeletion': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        )
      }
    }
  },
  '& .super-app-theme--Deleted': {
    backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode)
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        )
      }
    }
  }
}));

export interface OnlineQrBackendItem {
  id: number;
  unique: string;
  date: string;
  cust: string;
  custLat: string;
  p: string;
  dob: string;
  doc: string;
  rnokpp: string;
}

const columns: GridColDef[] = [
  // { field: 'docid', headerName: 'Document', width: 100 },
  // { field: 'transactionsid', headerName: 'Transaction', width: 100 },
  // { field: 'platid', headerName: 'Plat ID', width: 70 },
  // { field: 'terminalid', headerName: 'Terminal', width: 80 },
  { field: 'transname', headerName: 'Transaction', width: 140 },
  {
    field: 'datetime',
    headerName: 'Date',
    width: 150,
    valueGetter: ({ value }) => moment(value).format('DD-MM-YYYY HH:mm')
  },
  { field: 'amount', headerName: 'Amount', width: 80 },
  { field: 'comis', headerName: 'Fee', width: 80 },
  // { field: 'bonds', headerName: 'Rrn', width: 150 },
  // { field: 'paymentid', headerName: 'Payment ID', width: 100 },
  // { field: 'docstate', headerName: 'docstate', width: 100 },
  // { field: 'paymentstate', headerName: 'paymentstate', width: 100 },
  // { field: 'debitname', headerName: 'Payer', width: 250 },
  // { field: 'credit', headerName: 'Receiver', width: 250 },
  { field: 'purpose', headerName: 'Purpose', width: 1000 }
];

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.Mui-expanded': {
    marginTop: 0
  }
}));

export default function PAPaymentsList() {
  const [transactionList, setTransactionList] = useState<PaymentTransactionRow[]>([]);
  const [transactionListFiltred, setTransactionListFiltred] = useState<PaymentTransactionRow[]>([]);

  const [backendSelected, setBackendSelected] = useState<number>(0);
  const [terminalSelected, setTerminalSelected] = useState<string>('');

  const [showOnlyMainDocuments, setShowOnlyMainDocuments] = useState<boolean>(true);

  const [purposeOfPayment, setPurposeOfPayment] = useState<string>('');
  const [dateBegin, setDateBegin] = useState<Date | undefined>();
  const [dateEnd, setDateEnd] = useState<Date | undefined>();

  const [isDataLoading, setDataLoading] = useState<boolean>(false);

  const [needDataLoad, setNeedDataLoad] = useState<boolean>(false);

  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);

  const [selectedDocument, setSelectedDocument] = useState<PaymentTransactionRow | undefined>(
    undefined
  );

  const [transactionData, setTransactionData] = useState<PaymentTransactionRow>();

  useEffect(() => {
    if (selectedDocument) {
      console.log(selectedDocument);
      instance
        .post(`/pa/${backendSelected}/operations/extended`, {
          terminal: terminalSelected,
          id: selectedDocument.docid,
          pos: true
        })
        .then((value) => {
          setTransactionData(
            value.data.find((v: PaymentTransactionRow) => v.docid === selectedDocument.docid)
          );
        })
        .catch((reason) => {});
    }
  }, [selectedDocument]);

  const loadPayment = (
    backendId: number,
    terminal: string,
    begin: string,
    end: string,
    purpose: string
  ) => {
    setDataLoading(true);
    instance
      .post(`/pa/${backendId}/operations`, { terminal, begin, end, purpose })
      .then((value) => {
        if (selectedDocument) {
          setSelectedDocument(value.data.find((v: any) => v.docid == selectedDocument.docid));
        }
        setTransactionList(value.data);
        setDataLoading(false);
      })
      .catch((reason) => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    // if (transactionList.length > 0) {
    if (showOnlyMainDocuments) {
      setTransactionListFiltred(transactionList.filter((v: any) => v.parentid == '0'));
    } else {
      setTransactionListFiltred(transactionList);
    }
    // }
  }, [transactionList, showOnlyMainDocuments]);

  useEffect(() => {
    if (needDataLoad) {
      setNeedDataLoad(false);
      if (backendSelected > 0 && terminalSelected.length > 0 && needDataLoad) {
        loadPayment(
          backendSelected,
          terminalSelected,
          moment(dateBegin!).format('YYYY-MM-DDTHH:mm:ss'),
          moment(dateEnd!).format('YYYY-MM-DDTHH:mm:ss'),
          purposeOfPayment
        );
      }
    }
  }, [needDataLoad]);

  useEffect(() => {
    clearFilter();
  }, [backendSelected]);

  const clearFilter = () => {
    setDateBegin(moment(new Date()).startOf('day').toDate());
    setDateEnd(moment(new Date()).endOf('day').toDate());
    setPurposeOfPayment('');
    // setNeedDataLoad(true);
  };

  return (
    <React.Fragment>
      {isFilterOpen && (
        <PopupDialog
          title="Select option and apply"
          onClose={() => {
            setFilterOpen(false);
          }}
          maxWidth={'sm'}
          actions={
            <React.Fragment>
              <Button
                color={'inherit'}
                variant="text"
                onClick={() => {
                  clearFilter();
                  setFilterOpen(false);
                }}
                disabled={isDataLoading}
              >
                Clear
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setNeedDataLoad(true);
                  setFilterOpen(false);
                }}
                disabled={
                  isDataLoading || !dateBegin || !dateEnd || !terminalSelected || !backendSelected
                }
              >
                Apply
              </Button>
            </React.Fragment>
          }
        >
          <Box sx={{ mx: 1, mt: 1 }}>
            <PABackendSelect
              backend={backendSelected}
              setBackendId={(v) => {
                setBackendSelected(v);
              }}
            />
          </Box>
          <Box sx={{ mx: 1, mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <MobileDateTimePicker
                    closeOnSelect
                    label="Datetime begin"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={dateBegin}
                    onChange={(e) => {
                      setDateBegin(e ? new Date(e) : undefined);
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <MobileDateTimePicker
                    closeOnSelect
                    label="Datetime end"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={dateEnd}
                    onChange={(e) => {
                      setDateEnd(e ? new Date(e) : undefined);
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <PATerminalSelect
                    terminal={terminalSelected}
                    disabled={!backendSelected}
                    backend={backendSelected}
                    setTerminalId={(v) => {
                      setTerminalSelected(v);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    required
                    fullWidth
                    label="Purpose"
                    value={purposeOfPayment}
                    onChange={(e) => {
                      setPurposeOfPayment(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div style={{ display: 'flex', height: '100%', verticalAlign: 'middle' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showOnlyMainDocuments}
                          onChange={(e) => setShowOnlyMainDocuments(e.target.checked)}
                        />
                      }
                      label="Only main documents"
                    />
                  </div>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>
        </PopupDialog>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppMenu
          additionalLeftActionButton={
            <IconButton
              onClick={() => {
                setFilterOpen(true);
              }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <TuneIcon />
            </IconButton>
          }
        />
        <CssBaseline />
        <Box sx={{ m: 1, flex: 1, width: 'auto' }}>
          <StyledDataGrid
            onRowClick={(params, event) => {
              setSelectedDocument(params.row);
            }}
            // rowHeight={30}
            disableColumnFilter
            loading={isDataLoading}
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            // autoPageSize
            disableSelectionOnClick
            rows={transactionListFiltred}
            columns={columns}
            getRowId={(row: any) => `${row.docid}`}
            getRowClassName={(params) => {
              switch (params.row.deleted) {
                case '1':
                  return `super-app-theme--Deleted`;
                case '2':
                  return `super-app-theme--PendingDeletion`;
                default:
                  if (params.row.parentid == '0') return `super-app-theme--MainDocument`;
                  return ``;
              }
            }}
          />
        </Box>

        {selectedDocument && transactionData && (
          <PAPaymentActionDialog
            terminal={terminalSelected}
            data={transactionData}
            backend={backendSelected}
            onClose={() => setSelectedDocument(undefined)}
            needReload={() => {
              setNeedDataLoad(true);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
