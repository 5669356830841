import React, { useEffect, useState } from 'react';
import instance from '../../axios';
import {
  Alert,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import PopupDialog from '../../Components/Dialog/PopupDialog';
import Button from '@mui/material/Button';
import { UIPermissionContext } from '../../App';
import { UserPermissionList } from '../../enums/UserPermissionList';

interface Props {
  uuid: string;
  backend: number;
  provider: number;
  payment: number;
  onClose: () => void;
}

export default function PaymentReverseDialog(props: Props) {
  const uiPermission = React.useContext(UIPermissionContext);

  const [isReverseAvailable, setReverseAvailable] = useState<boolean>(false);
  const [isReverseAvailablePending, setReverseAvailablePending] = useState<boolean>(false);

  const [isMakeReversePending, setMakeReversePending] = useState<boolean>(false);

  const checkReverseAvailability = (
    uuid: string,
    backend: number,
    paymentProviderId: number,
    attemptId: number
  ) => {
    setReverseAvailablePending(true);
    instance
      .post(`/oq/s2s/${backend}/qr/cart/reverse-check`, { uuid, paymentProviderId, attemptId })
      .then((value) => {
        setReverseAvailable(value.data);
        setReverseAvailablePending(false);
      })
      .catch((reason) => {});
  };

  const makeReverseImmediately = (
    uuid: string,
    backend: number,
    paymentProviderId: number,
    attemptId: number,
    force: boolean
  ) => {
    setMakeReversePending(true);

    instance
      .post(`/oq/s2s/${backend}/qr/cart/reverse-immediately`, {
        uuid,
        paymentProviderId,
        attemptId,
        force: force
      })
      .then((r: any) => {
        console.log(r.data);
        if (r.data.acquire && r.data.acquire.body && r.data.acquire.url) {
          const form = document.createElement('form');
          form.setAttribute('action', r.data.acquire.url);
          form.setAttribute('method', 'post');
          form.setAttribute('target', '_blank');

          for (const [key, value] of Object.entries(r.data.acquire.body)) {
            const input = document.createElement('input');
            input.setAttribute('hidden', 'hidden');
            input.setAttribute('name', key);
            input.setAttribute('value', value as string);
            form.appendChild(input);
          }
          document.documentElement.appendChild(form).submit();
        }

        setMakeReversePending(false);
        props.onClose();
      })
      .catch((reason) => {
        setMakeReversePending(false);
      });
  };

  useEffect(() => {
    if (props.uuid && props.backend && props.provider && props.payment) {
      checkReverseAvailability(props.uuid, props.backend, props.provider, props.payment);
    }
  }, [props.uuid, props.backend, props.provider, props.payment]);

  return (
    <React.Fragment>
      {props.uuid && props.backend && props.provider && props.payment && (
        <PopupDialog
          title={props.uuid}
          onClose={() => {
            props.onClose();
          }}
          maxWidth={'sm'}
        >
          {isReverseAvailablePending ? (
            <CircularProgress />
          ) : (
            <>
              {isReverseAvailable ? (
                <>
                  <Alert severity="success" sx={{ marginBottom: 2 }}>
                    Refund is available
                  </Alert>
                  <Alert severity="error">
                    <b>cancellation will be made immediately</b>
                  </Alert>
                  <Button
                    sx={{ mt: 2 }}
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      makeReverseImmediately(
                        props.uuid,
                        props.backend,
                        props.provider,
                        props.payment,
                        false
                      );
                    }}
                    disabled={isMakeReversePending}
                  >
                    Refund
                  </Button>
                </>
              ) : (
                <>
                  {uiPermission.check([UserPermissionList.AllowQrCartItemMakeRefundForce]) && (
                    <>
                      <Alert severity="error" sx={{ marginBottom: 2 }}>
                        FORCE REFUND AVAILABLE ONLY. VERY DANGEROUS.
                      </Alert>
                      <Alert severity="error">
                        <b>
                          THIS ACTION ONLY RETURN FUNDS. WITHOUT PROCESSING ASKING AND DOCUMENTS
                          DELETING.
                        </b>
                      </Alert>
                      <Button
                        sx={{ mt: 2, mb: 2 }}
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={() => {
                          makeReverseImmediately(
                            props.uuid,
                            props.backend,
                            props.provider,
                            props.payment,
                            true
                          );
                        }}
                        disabled={isMakeReversePending}
                      >
                        FORCE REFUND
                      </Button>
                    </>
                  )}
                  <Alert severity="warning">Refund locked</Alert>
                </>
              )}
            </>
          )}
        </PopupDialog>
      )}
    </React.Fragment>
  );
}
