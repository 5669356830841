import React, { useEffect, useState } from 'react';
import instance from '../../axios';
import { Dialog, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import PopupDialog from '../../Components/Dialog/PopupDialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const emailRegexp =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

interface Props {
  uuid: string;
  backend: number;
  provider: number;
  onClose: () => void;
}

export default function EmailResend(props: Props) {
  const [isSending, setIsSending] = useState<boolean>(false);

  const [lastEmails, setLastEmails] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const [customEmail, setCustomEmail] = useState<string>('');

  const loadAttemptsHistory = (uuid: string, backend: number, paymentProviderId: number) => {
    instance
      .post(`/oq/s2s/${backend}/qr/cart/attempts/extended`, { uuid, paymentProviderId })
      .then((value) => {
        console.log(value.data.payment.statuses.map((v: { payerEmail: string }) => v.payerEmail));
        setLastEmails(value.data.payment.statuses.map((v: { payerEmail: string }) => v.payerEmail));
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    if (props.uuid && props.backend && props.provider) {
      loadAttemptsHistory(props.uuid, props.backend, props.provider);
    }
  }, [props.uuid, props.backend, props.provider]);

  const sendEmail = (
    payerEmail: string,
    uuid: string,
    paymentProviderId: number,
    backend: number
  ) => {
    setIsSending(true);
    instance
      .post(`/oq/s2s/${backend}/qr/cart/mail-pdf`, { payerEmail, uuid, paymentProviderId })
      .then((value) => {
        props.onClose();
        setIsSending(true);
      })
      .catch((reason) => {
        setIsSending(false);
      });
  };

  return (
    <React.Fragment>
      {props.uuid && props.backend && props.provider && (
        <PopupDialog
          title={props.uuid}
          onClose={() => {
            props.onClose();
          }}
          maxWidth={'sm'}
          actions={
            <React.Fragment>
              <Button
                disabled={
                  isSending || (selectedEmail === '' ? !emailRegexp.test(customEmail) : false)
                }
                onClick={sendEmail.bind(
                  null,
                  selectedEmail || customEmail,
                  props.uuid,
                  props.provider,
                  props.backend
                )}
              >
                Send email
              </Button>
            </React.Fragment>
          }
        >
          <React.Fragment>
            <FormControl>
              <FormLabel id="rbg-1">Send mail to</FormLabel>
              <RadioGroup
                aria-labelledby="rbg-1"
                defaultValue={''}
                value={selectedEmail}
                onChange={(e) => {
                  setSelectedEmail(e.target.value);
                }}
              >
                {lastEmails.map((v) => (
                  <FormControlLabel value={v} control={<Radio />} label={v} disabled={isSending} />
                ))}
                <FormControlLabel value="" control={<Radio />} label="Another email address" />
              </RadioGroup>
            </FormControl>
            {selectedEmail === '' && (
              <TextField
                label="Enter email"
                variant="outlined"
                fullWidth
                sx={{ my: 1 }}
                value={customEmail}
                onChange={(event) => setCustomEmail(event.target.value)}
                disabled={isSending}
              />
            )}
          </React.Fragment>
        </PopupDialog>
      )}
    </React.Fragment>
  );
}
