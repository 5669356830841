import React, { useEffect, useState } from 'react';
import AppMenu from '../AppBar/AppMenu';
import CssBaseline from '@mui/material/CssBaseline';
import { Paper } from '@mui/material';
import instance from '../axios';

export default function Overview() {
  const [user, setUser] = useState<string>();

  useEffect(() => {
    instance
      .get('/overview', { withCredentials: true })
      .then((value) => {
        setUser(value.data);
      })
      .catch((reason) => {
        console.warn(reason);
      });
  }, []);

  return (
    <React.Fragment>
      <AppMenu header={'Overview'} />
      <CssBaseline />
      <Paper sx={{ m: 2 }} elevation={0}>
        Im {user} !{/*<PasswordChangeFields/>*/}
      </Paper>
    </React.Fragment>
  );
}
