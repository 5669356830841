import { Alert, Checkbox, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import encryptWithPublicKey from '../crypto';
import generatePassword from '../generator';
import PopupDialog from '../Components/Dialog/PopupDialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import instance from '../axios';

export interface Props {
  reloadParent: () => void;
  onClose: () => void;
}

export default function UserCreateDialog(props: Props) {
  const [userLogin, setUserLogin] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [isUserCreated, setUserCreated] = useState<boolean>(false);
  const [userCreateError, setUserCreateError] = useState<string>('');

  const createUser = async (login: string, password: string) => {
    // const encryptedData = await encryptWithPublicKey({
    //   login,
    //   password
    // });
    const encryptedData = {login, password};

    instance
      .post<boolean>('/user/list', { payload: encryptedData }, { withCredentials: true })
      .then((value) => {
        setUserPassword(password);
        setUserCreated(true);
        setUserCreateError('');
        props.reloadParent();
      })
      .catch((reason) => {
        switch (reason.response.status) {
          case 422:
            setUserCreateError('Username already exist. Select another.');
            break;
        }
      });
  };

  const beginCreatingUser = (login: string) => {
    createUser(login, generatePassword(16));
  };

  useEffect(() => {
    if (userCreateError) setUserCreateError('');
    if (isUserCreated) {
      setUserPassword('');
      setUserCreated(false);
      setUserCreateError('');
    }
  }, [userLogin]);

  useEffect(() => {
    setUserPassword('');
    setUserLogin('');
    setUserCreated(false);
    setUserCreateError('');
  }, []);

  return (
    <PopupDialog
      title={'Create new user'}
      maxWidth={'sm'}
      onClose={() => {
        props.onClose();
      }}
    >
      <React.Fragment>
        <TextField
          margin="normal"
          required
          fullWidth
          id="login"
          label="Login"
          name="login"
          autoComplete="login"
          autoFocus
          value={userLogin}
          onChange={(e) => {
            setUserLogin(e.target.value);
          }}
        />
        {!isUserCreated && (
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            fullWidth
            onClick={beginCreatingUser.bind(null, userLogin)}
            disabled={userCreateError.length > 0}
          >
            Create new user
          </Button>
        )}
        {userCreateError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {userCreateError}
          </Alert>
        )}
        {isUserCreated && (
          <React.Fragment>
            <TextField
              margin="normal"
              label="Password"
              fullWidth
              value={userPassword}
              disabled
              color={'error'}
            />
            <Alert severity="warning" sx={{ mt: 2 }}>
              User created, but by default dont have any access permission and ip whitelist access.
              Set ip and permission in user settings
            </Alert>
          </React.Fragment>
        )}
      </React.Fragment>
    </PopupDialog>
  );
}
