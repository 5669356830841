import { useEffect, useState } from 'react';
import instance from '../axios';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import React from 'react';

interface Props {
  terminal: string;
  backend: number;
  disabled: boolean;
  setTerminalId(id: string): void;
  allowSelectAll?: boolean;
}

export interface OnlineQrBackendItem {
  id: number;
  name: string;
}

export default function PATerminalSelect(props: Props) {
  const [showClosed, setShowClosed] = useState<boolean>(false);

  const [backendList, setBackendList] = useState<OnlineQrBackendItem[]>([]);
  const [terminalSelected, setTerminalSelected] = useState<string>('');
  const [terminalSelectedInit, setTerminalSelectedInit] = useState<string>('');

  const loadTerminalList = (showClosed: boolean, backend: number) => {
    instance
      .get(`/pa/${backend}/terminals${showClosed ? `?showClosed=1` : ``}`)
      .then((value) => {
        setBackendList(value.data);
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    loadTerminalList(showClosed, props.backend);
  }, [showClosed]);

  useEffect(() => {
    if (props.backend > 0) {
      loadTerminalList(false, props.backend);
    }
  }, [props.backend]);

  useEffect(() => {
    setTerminalSelectedInit(props.terminal);
  }, [props.terminal]);

  useEffect(() => {
    if (terminalSelectedInit !== terminalSelected) props.setTerminalId(terminalSelected);
  }, [terminalSelected]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', verticalAlign: 'middle' }}>
        <FormControlLabel
          control={
            <Checkbox value={showClosed} onChange={(e) => setShowClosed(e.target.checked)} />
          }
          label="Closed"
          disabled={props.disabled}
        />
      </div>
      <FormControl fullWidth>
        <InputLabel id="label">Terminal</InputLabel>
        <Select
          labelId="label"
          value={terminalSelected || terminalSelectedInit || ''}
          label="Terminal"
          disabled={props.disabled}
          onChange={(e) => {
            setTerminalSelected(e.target.value);
          }}
        >
          {props.allowSelectAll && (
            <MenuItem key={`sv-all`} value="all">
              All
            </MenuItem>
          )}
          {backendList.map((value, index) => (
            <MenuItem key={`sv-${index}`} value={value.id}>
              {value.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
