import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { ColorModeContext, UIPermissionContext } from '../App';
import React, { useState } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import { UserPermissionList } from '../enums/UserPermissionList';
import { Link } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import BallotIcon from '@mui/icons-material/Ballot';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { requestForToken } from '../firebase';
import instance from '../axios';
import setCredential from '../Auth/setCredential';
import ListAltIcon from '@mui/icons-material/ListAlt';

export interface Props {
  header?: string;
  additionalLeftActionButton?: JSX.Element;
}

export default function AppMenu(props: Props) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const uiPermission = React.useContext(UIPermissionContext);

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const [isOnlineQROpen, setOnlineQROpen] = useState<boolean>(false);
  const [isPAOpen, setPAOpen] = useState<boolean>(false);

  const enableNotifications = async () => {
    const token = await requestForToken();
    if (token) {
      instance
        .post('/user/fcm/add', { fcmToken: token })
        .then((res) => {
          console.log(res.data);
        })
        .catch((reason) => {
          console.log(reason.message);
        });
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          onClick={colorMode.toggleColorMode}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        {props.additionalLeftActionButton && <>{props.additionalLeftActionButton}</>}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {props.header}
        </Typography>
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={() => {
            setMenuOpen(!isMenuOpen);
          }}
          sx={{ ml: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        anchor={'right'}
        open={isMenuOpen}
        onClose={() => {
          setMenuOpen(!isMenuOpen);
        }}
      >
        <React.Fragment>
          <List>
            <ListItemButton component={Link} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </ListItemButton>
            {uiPermission.check([UserPermissionList.ViewUsersList]) && (
              <ListItemButton component={Link} to="/user">
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="User settings" />
              </ListItemButton>
            )}
            <ListItemButton
              onClick={() => {
                setOnlineQROpen(!isOnlineQROpen);
              }}
            >
              <ListItemIcon>
                <QrCodeScannerIcon />
              </ListItemIcon>
              <ListItemText primary="Online QR" />
              {isOnlineQROpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isOnlineQROpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {uiPermission.check([UserPermissionList.ViewQrCartList]) && (
                  <ListItemButton component={Link} to="/oq/cart" sx={{ ml: 4 }}>
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary="QR cart tool" />
                  </ListItemButton>
                )}
                {uiPermission.check([UserPermissionList.ViewQrBackendsList]) && (
                  <ListItemButton component={Link} to="/oq/settings" sx={{ ml: 4 }}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                )}
                {uiPermission.check([UserPermissionList.ViewQrCartProcessingTest]) && (
                  <ListItemButton component={Link} to="/oq/processing/test" sx={{ ml: 4 }}>
                    <ListItemIcon>
                      <StorageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Processing test" />
                  </ListItemButton>
                )}
                {uiPermission.check([UserPermissionList.ViewQrBackendsList]) && (
                  <ListItemButton component={Link} to="/oq/backend" sx={{ ml: 4 }}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Backend settings" />
                  </ListItemButton>
                )}
              </List>
            </Collapse>

            <ListItemButton
              onClick={() => {
                setPAOpen(!isPAOpen);
              }}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Payment processing" />
              {isPAOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isPAOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {uiPermission.check([UserPermissionList.ViewPaymentBackendOperationsList]) && (
                  <ListItemButton component={Link} to="/pa/transactions" sx={{ ml: 4 }}>
                    <ListItemIcon>
                      <BallotIcon />
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                  </ListItemButton>
                )}
                {uiPermission.check([UserPermissionList.AllowUnblockFromSanctionsList]) && (
                  <ListItemButton component={Link} to="/pa/sanctions-list" sx={{ ml: 4 }}>
                    <ListItemIcon>
                      <PersonOffIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sanctions list" />
                  </ListItemButton>
                )}
                {uiPermission.check([UserPermissionList.ViewRegistryList]) && (
                  <ListItemButton component={Link} to="/pa/registry" sx={{ ml: 4 }}>
                    <ListItemIcon>
                      <BackupTableIcon />
                    </ListItemIcon>
                    <ListItemText primary="Registry" />
                  </ListItemButton>
                )}
              </List>
            </Collapse>
            {uiPermission.check([UserPermissionList.ViewTaskList]) && (
              <ListItemButton component={Link} to="/task/list">
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Task" />
              </ListItemButton>
            )}
            <ListItemButton onClick={enableNotifications.bind(null)}>
              <ListItemIcon>
                <AddAlertIcon />
              </ListItemIcon>
              <ListItemText primary="Notifications" />
            </ListItemButton>
          </List>
        </React.Fragment>
      </Drawer>
    </AppBar>
  );
}
